export class SettingsDocumentFormMessages {
    settings_messages = {
        name: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_255_CHARACTERS'}
        ],
        description: [
            { type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_255_CHARACTERS'}
        ],
        template_category_type_id: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        content: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ]
    };

    getSettingsDocumentFormMessages() {
        return this.settings_messages;
    }
}