// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*export const environment = {
 
  production: false,
  apiUrl: 'http://localhost/finergia-api/public/api/',
  sessionTimeOut: 1,
  stripePublicKey: 'pk_test_51KemyAKEXDcdwO4SboLCtQujMsa3nNPWzBcirDrV4GJ6JaOYwaFdDSUZRVciWSon9msMSd8qRuqq5Qlu3JSOX0LA00MQuVrsP0',
  refresh_datatable_assigned: 240000,
  websocket: {
    cluster: 'mt1',
    wsHost: 'localhost',
    wssPort: 6001,
    wsPort: 6001,
    // wsPort: 6001,
    httpHost: 'localhost:6001',
    httpPort: 6001,
    enableStats: true,
    enabledTransports: ['ws'],
    forceTLS: false,
    authEndpoint: 'http://localhost/finergia-api/public/api/broadcasting/auth',
  },
};*/
export const environment = {
	production: false,
	apiUrl: 'https://dev.restapi.finergia.es/api/',
	sessionTimeOut: 1,
	stripePublicKey: 'pk_test_51KemyAKEXDcdwO4SboLCtQujMsa3nNPWzBcirDrV4GJ6JaOYwaFdDSUZRVciWSon9msMSd8qRuqq5Qlu3JSOX0LA00MQuVrsP0',
	refresh_datatable_assigned: 240000,
	websocket: {
	  cluster: 'mt1',
	  wsHost: 'dev.core.websocket.finergia.es',
	  wssPort: 6001,
	  wsPort: 6001,
	  // wsPort: 6001,
	  httpHost: 'localhost:6001',
	  httpPort: 6001,
	  enableStats: true,
	  enabledTransports: ['ws'],
	  forceTLS: true,
	  authEndpoint: 'https://dev.restapi.finergia.es/api/broadcasting/auth',
	}
  };
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
