import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromStateMe from './+state/state-me.reducer';
import { StateMeEffects } from './+state/state-me.effects';
import { BackendModule } from '@finergia-core/backend';
import { StateMeService } from './state-me.service';
import { stateMeFacade } from './+state/state-me.facade';
import { VerificaTokenGuard } from './guards/verifica-token.guard';
import { SharedModule } from '@finergia-core/shared';

@NgModule({
  imports: [
    CommonModule,
    BackendModule,
    SharedModule,
    StoreModule.forFeature(
      fromStateMe.STATE_ME_FEATURE_KEY,
      fromStateMe.reducer
    ),
    EffectsModule.forFeature([StateMeEffects]),
  ],
  providers: [StateMeService , stateMeFacade, VerificaTokenGuard]
})
export class StateMeModule {}
