export interface BusinessProfile {

    id: number,
    company_profile_type_id: number,
    company_profile_type: {
        id: number,
        name: string
    },
    commercial_name: string,
    contact_address_email:string,
    cnae: string,
    contact_address: string,
    contact_address_number: string,
    contact_address_floor: string,
    contact_address_postal_code: string,
    contact_address_locality: string,
    contact_address_province: string,
    contact_address_phone: string,
    contact_address_mobile_phone: string,
    contact_address_person_name: string,
    social_name: string,
    identification_document: string,
    legal_address: string,
    legal_address_number: string,
    legal_address_floor: string,
    legal_address_postal_code: string,
    legal_address_locality: string,
    legal_address_province: string,
    legal_address_phone: string,
    legal_address_mobile_phone: string,
    legal_address_email: string,
    legal_address_country_id:number,
    terms_accepted: boolean,
    url_image: string,
    is_active: boolean,
    created_at: string,
    updated_at: string,
    image:string,
}

export class BusinessProfile  implements BusinessProfile  {
	constructor(){
        this.id = 0,
        this.company_profile_type_id = 0,
        this.company_profile_type = {
            id: 0,
            name: ''
        },
        this.commercial_name = '',
        this.cnae = '',
        this.contact_address = '',
        this.contact_address_number = '',
        this.contact_address_floor = '',
        this.contact_address_postal_code = '',
        this.contact_address_locality = '',
        this.contact_address_province = '',
        this.contact_address_phone = '',
        this.contact_address_mobile_phone = '',
        this.contact_address_email ='',
        this.contact_address_person_name = '',
        this.social_name = '',
        this.identification_document = '',
        this.legal_address = '',
        this.legal_address_number = '',
        this.legal_address_floor = '',
        this.legal_address_postal_code = '',
        this.legal_address_locality = '',
        this.legal_address_province = '',
        this.legal_address_email = '',
        this.legal_address_phone = '',
        this.legal_address_mobile_phone = '',
        this.legal_address_country_id = 0,
        this.terms_accepted = false,
        this.url_image = '',
        this.is_active = false,
        this.created_at = '',
        this.updated_at = '',
        this.image =''
	}
}
