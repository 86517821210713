
export class FormsMessages {
    FormMessages = {
        name: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS' },
            { type: 'maxlength', message: 'VALIDATIONS.MAXIMUN_255_CHARACTERS' },
            { type: 'pattern', message: 'VALIDATIONS.INVALID_FORMAT' },
        ],
        form_type_id: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }
        ],
        is_active:[
            { type: 'required', message: 'VALIDATIONS.INVALID_FORMAT' },
        ],
        order:[
            { type: 'required', message: 'VALIDATIONS.INVALID_FORMAT' },
        ]
        
    };

    getFormsMessages() {
        return this.FormMessages;
    }
}
