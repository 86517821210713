import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'finergia-core-error-alert',
  templateUrl: './error-alert.component.html',
  styleUrls: ['./error-alert.component.css'],
})
export class ErrorAlertComponent implements OnInit {
  @Input() error: any;
  message: any;
  constructor() {}

  ngOnInit(): void {
    let errores = Object.keys(this.error.error);
    errores.forEach((element) => {
      this.message = this.error.error[element];
      console.log(this.message);
    });
  }
}
