export interface Clients {
    id: number,
    name: string,
    code:string,
    cif: string,
    clientBill: string,
    paymentType: number,
    ingress: [],
    commercial_name:string,
    company_status_id: number
    identification_document: string;
    contable_account_description?:string;
    contable_account?:number;
    company_payment_method_id?:number;
    company_code_income_outcome?:[];

   
}

export class Clients  implements Clients  {
	constructor(){
        this.id = 0,
        this.name = '',
        this.code = '',
        this.cif = '',
        this.clientBill = '',
        this.paymentType = 0,
        this.ingress =[],
        this.commercial_name ='',
        this.company_status_id =0,
        this.identification_document = '',
        this.contable_account_description = '';
        this.contable_account = 0;
        this.company_payment_method_id = 0;
        this.company_code_income_outcome =[];
        
	}
}
