import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { BackendService } from '../../../../../../../libs/backend/src/lib/backend.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../../../../../libs/shared/src/lib/services/toast.service';
import { take } from 'rxjs';
import { LoadingService } from '../../../../../../../libs/shared/src/lib/services/loading.service';

@Component({
  selector: 'finergia-core-change-currency',
  templateUrl: './change-currency.component.html',
  styleUrls: ['./change-currency.component.css'],
})
export class ChangeCurrencyComponent implements OnInit {

  cuerrencySelected: string | undefined;
  
  currencies: any= [];

  loadingCurrency : string ='loading';

  showCurrency: boolean= false;

  constructor(
    private backend: BackendService,
    private translate: TranslateService,
    private activeModal: NgbActiveModal,
    private loading: LoadingService,
    private toast: ToastService,
    private detectchange:ChangeDetectorRef,
    
  ) {}

  ngOnInit(): void {
    this.getCurrency();
  }

  close(){
    this.activeModal.close();
  }

  getCurrency(){

    this.showCurrency = false;

    this.loadingCurrency = 'load';

    
    this.backend.get('currency').pipe(take(1)).subscribe((data: any) => {

      
      this.currencies = data.data;


      this.loadingCurrency ='succes';

      this.showCurrency = true;

      this.detectchange.detectChanges();


    }, (error: any) => {

      this.loadingCurrency ='succes';

      this.showCurrency = true;

       this.toast.displayHTTPErrorToast(error.status, error.error.error);
     
    });
  }

  changeLanguage(language: any){
    this.backend.post('company_preference', {
      language_id: language.id
    }).pipe(take(1)).subscribe((data)=>{
      this.translate.setDefaultLang(language.language);
      this.translate.use(language.language);
      location.reload();
      this.activeModal.close();
    }, error => {
      this.toast.displayHTTPErrorToast(error.status, error.error.error, this.translate.instant('GENERAL.ACCEPT'));
    })
  }

  updatePreferences(send: any){
 

    this.loading.showLoading();
  
    this.backend.post('company_preference', {currency_id:send.id}).pipe(take(1)).subscribe((data: any) => {
  
    

      this.loading.hideLoading();
  
  
      location.reload();
     
      this.activeModal.close();


      this.toast.displayWebsiteRelatedToast(
  
        this.translate.instant('GENERAL.SUCCESSFUL_UPDATE'),
        this.translate.instant('GENERAL.ACCEPT'),  
      );
  
    }, (error: any) => {
  
        this.loading.hideLoading();
  
       this.toast.displayHTTPErrorToast(error.status, error.error.error);
     
    });
  }
}
