export class TicketMessages {
    ticketMessages = {
        ticketNumber: [
            { type: 'min', message: 'minimo 1' },
            { type: 'max', message: 'maximo 200' },
            
        ],
        document: [
            { type: 'required', message: 'Campo requerido.' },
           
        ],
       
    };

    getTicketMessages() {
        return this.ticketMessages;
    }
}
