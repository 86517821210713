export class ValidationsMessages {
    validations_messages = {
        name: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'maxlength', message: 'VALIDATIONS.MAXIMUN_255_CHARACTERS' },
        ],
        last_name: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        description: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        background_color: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        price: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        contable_address_mobile_phone: [

            {type: 'invalidPhone', message: 'VALIDATIONS.INVALID_PHONE_IN' },
        ],
        contable_address_phone: [
            {type: 'invalidPhone', message: 'VALIDATIONS.INVALID_PHONE_IN' },
        ],
        company_id: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        email: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'email', message: 'VALIDATIONS.IT_MUST_BE_IN_THE_FORMAT_ABCD' }
        ],
        account_number: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ], 
        description_account: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        account_seat: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        concept: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        client_provider_name: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        document: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        debit: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        credit: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        balance: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        counterpart: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        code: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        identification_document: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
       
        contable_account: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        payment_method: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        contable_account_description:[
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        associated_income: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            
        ],

        date: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        account_description_separator:  [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        subject:  [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        password: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'invalid', message: 'VALIDATIONS.WRONG_FIELD' },
            { type: 'minlength', message: 'VALIDATIONS.MINIMUN_8_CHARACTERS' },
        ],
        password_confirmation: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'confirmar', message: 'VALIDATIONS.PASSWORDS_DO_NOT_MATCH' },
        ],
        Comment: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        bill: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'maxlength', message: 'VALIDATIONS.MAXIMUN_10' }
        ],
        bill_date: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'maxlength', message: 'VALIDATIONS.MAXIMUN_10' }
        ],
        import: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'maxlength', message: 'VALIDATIONS.MAXIMUN_10' }

        ],
        bill_due_date: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'maxlength', message: 'VALIDATIONS.MAXIMUN_10' } 

        ],
    };

    getValidationsMessages() {
        return this.validations_messages;
    }
}
