import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'finergia-core-modal-general-renew-sync',
  templateUrl: './modal-general-renew-sync.component.html',
  styleUrls: ['./modal-general-renew-sync.component.scss'],
})
export class ModalGeneralRenewSyncComponent implements OnInit {
  
  data: any;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }
  closeModal(value: any){
    this.activeModal.close(value);
  }
}
