import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'finergia-core-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.scss'],
})
export class GaugeChartComponent implements OnInit {

  @Input('canvasWidth') canvasWidth: any ;

  @Input('needleValue')  needleValue: any;

  @Input('centralLabel')  centralLabel: any;

  @Input('name')  name: any ;

  @Input('bottomLabel')  bottomLabel: any ;

  @Input('options')  options : any;
  constructor() {}

  ngOnInit(): void {}
}
