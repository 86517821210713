import { Injectable } from '@angular/core';
import { LoadingService, ToastService } from '@finergia-core/shared';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import { StateMeService } from '../state-me.service';

import * as StateMeActions from './state-me.actions';

@Injectable()
export class StateMeEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StateMeActions.LoginActionType.LOGIN),
      mergeMap((action: any) =>{
        return this.service.login(action.payload).pipe(
          map((data: any) => {
            this.service.guardarStorage(data);
            return new StateMeActions.LoginSuccessAction({ login: data})
          }),
          catchError(error => of(new StateMeActions.LoginFailAction(error)))
        );
      })
    )
  );

  loginFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StateMeActions.LoginActionType.LOGIN_FAIL),
        map((action: StateMeActions.LoginFailAction) => action),
        tap((action) => {
          this.loading.hideLoading();
          this.toast.displayHTTPErrorToast(action.payload.error.code, action.payload.error.error)
        })
      ),
      { dispatch: false }
  );

  me$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StateMeActions.LoginActionType.LOAD_ME),
      mergeMap((action: any) =>{
        return this.service.loadMe().pipe(
          map(({data}: any) => {
            return new StateMeActions.LoadMeSuccessAction({
              email: data.email,
              last_name: data.last_name,
              menu: data.menu,
              name: data.name,
              company: data.company,
              identification_document: data.identification_document,
              phone: data.phone,
              url_image: data.url_image,
              profile: data.profile,
              profesional_menu: data.profesional_menu,
              company_switch_list: data.company_switch_list,
              company_preference: data.company_preference,
              demo_left_days: data.demo_left_days,
              company_subscription_status:  data.company_subscription_status,
              use_profiles_admin: data.use_profiles_admin,
              use_subscription: data.use_subscription,
              company_parent: data.company_parent
            })
          }),
          catchError(error => of(new StateMeActions.LoadMeFailAction(error)))
        );
      })
    )
  );

  loadFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StateMeActions.LoginActionType.LOAD_ME_FAIL),
        map((action: StateMeActions.LoadMeFailAction) => action),
        tap((action) => {
          console.log(action);
          this.toast.displayHTTPErrorToast(action.payload.error.code, action.payload.error.error)
        })
      ),
      { dispatch: false }
  );

  updateMe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StateMeActions.LoginActionType.UPDATE_ME),
      mergeMap((action: any) =>{
        return this.service.updateMe(action.payload).pipe(
          map(({data}: any) => {
            return new StateMeActions.UpdateMeSuccessAction({
              email: data.email,
              last_name: data.last_name,
              name: data.name,
              identification_document: data.identification_document,
              phone: data.phone,
              url_image: data.url_image,
            })
          }),
          catchError(error => of(new StateMeActions.LoginFailAction(error)))
        );
      })
    )
  );

  updateFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StateMeActions.LoginActionType.UPDATE_ME_FAIL),
        map((action: StateMeActions.UpdateMeFailAction) => action),
        tap((action) => {
          console.log(action);
          this.toast.displayHTTPErrorToast(action.payload.error.code, action.payload.error.error)
        })
      ),
      { dispatch: false }
  );

  updateImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StateMeActions.LoginActionType.UPDATE_IMAGE),
      mergeMap((action: any) =>{
        return this.service.updateImage(action.payload).pipe(
          map(({data}: any) => {
            return new StateMeActions.UpdateImageSuccessAction({
              image: data.url_image
            })
          }),
          catchError(error => of(new StateMeActions.LoginFailAction(error)))
        );
      })
    )
  );

  updateImageFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StateMeActions.LoginActionType.UPDATE_IMAGE_FAIL),
        map((action: StateMeActions.UpdateImageFailAction) => action),
        tap((action) => {
          console.log(action);
          this.toast.displayHTTPErrorToast(action.payload.error.code, action.payload.error.error)
        })
      ),
      { dispatch: false }
  );

  constructor(private readonly actions$: Actions,
    private service: StateMeService,
    private loading: LoadingService,
    private toast: ToastService) {}
}
