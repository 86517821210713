import { Injectable } from '@angular/core';
import { stateClientSelector } from '@finergia-core/state-client';
import { Store, select } from '@ngrx/store';
import { setInitalStateAction, SetStepAction, setStepOneAction, setStepTwoAction } from './state-client.actions';
import { Client } from './state-client.reducer';

@Injectable()
export class stateClientFacade {
    
    constructor(private store: Store<Client>) {}

    loaded$ = this.store.pipe(select(stateClientSelector.getStateClientLoaded));
    step$ = this.store.pipe(select(stateClientSelector.getStateClientStep));
    client$ = this.store.pipe(select(stateClientSelector.getStateClient));


    setSep(step: number){
        this.store.dispatch(new SetStepAction({ step }));
    }

    registerStepOne(register: Client){
        this.store.dispatch(new setStepOneAction({ register }));
    }

    registerStepTwo(register: Client){
        this.store.dispatch(new setStepTwoAction({ register }));
    }

    setInitalState(){
        this.store.dispatch(new setInitalStateAction());
    }
}
