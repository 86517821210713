export interface Register {

    commercial_name: string,

    cnae: string,

    contact_address: string,

    contact_address_number: string,

    contact_address_floor: string,

    contact_address_postal_code: string,

    contact_address_locality: string,

    contact_address_province: string,

    contact_address_phone: string,

    contact_address_mobile_phone: string,

    contact_address_person_name: string,

    // segunda parte 

    social_name: string,

    identification_document: string,

    legal_address: string,

    legal_address_number: string,

    legal_address_floor: string,

    legal_address_postal_code: string,

    legal_address_locality: string,

    legal_address_province: string,

    legal_address_phone: string,

    legal_address_mobile_phone: string,

    user_email: string,

    password: string,

    password_confirmation: string,

    terms_accepted: boolean,

    rate_id : number
  }

  export class Register  implements Register  {
	constructor() {

    this.commercial_name = '',

    this.cnae = '',

    this.contact_address = '',

    this.contact_address_number = '',

    this.contact_address_floor = '',

    this.contact_address_postal_code = '',

    this.contact_address_locality = '',

    this.contact_address_province ='',

    this.contact_address_phone = '+34',

    this.contact_address_mobile_phone = '+34',

    this.contact_address_person_name = '',

    //segunda parte

    this.social_name = '',

    this.identification_document = '',

    this.legal_address = '',

    this.legal_address_number = '',

    this.legal_address_floor = '',

    this.legal_address_postal_code = '',

    this.legal_address_locality = '',

    this.legal_address_province = '',

    this.legal_address_phone = '+34',

    this.legal_address_mobile_phone = '+34',

    this.user_email = '',

    this.password = '',

    this.password_confirmation = '',
    
    this.terms_accepted = false,
    
    this.rate_id = 0
    }
}