import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';

import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { HeaderComponent } from './shared/header/header.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { reducers, metaReducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { environment } from '@finergia-core/env/environment';
import { AppEffects } from './app.effects';
import { StateMeModule, VerificaTokenGuard } from '@finergia-core/state-me';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LoadingComponent } from './shared/loading/loading.component';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StateClientModule } from '@finergia-core/state-client';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  TranslateCompiler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { ChangeLanguageComponent } from './shared/modals/change-language/change-language.component';
import { ChangeCurrencyComponent } from './shared/modals/change-currency/change-currency.component';
import { CustomDatepickerI18n, Language, MomentDateFormatter, SharedModule } from '@finergia-core/shared';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

registerLocaleData(es);
@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    HeaderComponent,
    LoadingComponent,
    SidebarComponent,
    ChangeLanguageComponent,
    ChangeCurrencyComponent,
  ],
  imports: [
    BrowserModule,
    StateMeModule,
    StateClientModule,
    MatSnackBarModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
    RouterModule.forRoot(
      [
        {
          path: '',
          component: PagesComponent,
          loadChildren: () =>
            import('./pages/pages.module').then((m) => m.PagesModule),
          canActivate: [VerificaTokenGuard],
        },
        {
          path: 'login',
          loadChildren: () =>
            import('libs/login/src/lib/login.module').then(
              (mod) => mod.LoginModule
            ),
        },
       /*  {
          path: 'register',
          loadChildren: () =>
            import('libs/register/src/lib/register.module').then(
              (mod) => mod.RegisterModule
            ),
        }, */
        {
          path: 'register',
          loadChildren: () =>
            import('libs/register-new/src/lib/register-new.module').then(
              (mod) => mod.RegisterNewModule
            ),
        },
        {
          path: 'recover',
          loadChildren: () =>
            import('libs/recover/src/lib/recover.module').then(
              (mod) => mod.RecoverModule
            ),
        },
        {
          path: 'reset-password/:token',
          loadChildren: () =>
            import('libs/reset-password/src/lib/reset-password.module').then(
              (mod) => mod.ResetPasswordModule
            ),
        },
        {
          path: 'test-time',
          loadChildren: () =>
            import('libs/test-time/src/lib/test-time.module').then(
              (mod) => mod.TestTimeModule
            ),
        },
      ],
      { useHash: true, onSameUrlNavigation: 'reload' }
    ),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([AppEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    BrowserAnimationsModule,
    NgbModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'es-ES',
    },
    Language,
    { provide: NgbDateParserFormatter, useClass: MomentDateFormatter },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
