
export class TreasuryMessages {
    treasuryMessages = {
        name: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }
        ],
        template_Id: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }
            
        ],
        treasury_results_payment_collection_status_id: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }
            
        ],
        treasury_result_company_item_id: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }
            
        ],
        treasury_Creditor_Debtor: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }
            
        ],
       
        concept: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_50_CHARACTERS'},
            {type: 'pattern', message: 'VALIDATIONS.INVALID_FORMAT' },
        ],
        excel_import:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }
        ],
        excel_file:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }
        ],
        year_import:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }
        ],
       
        treasury_Table_id: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        establish_concept:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        total_import:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        treasury_prevision_investment_type_id:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        treasury_prevision_investment_movement_type_id:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        import:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        second_import:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        second_expected_date:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        from_date:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        endDate:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        loan_name:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        bank_id:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        bank_company_product_id:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        start_Date:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        deadline_date:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        fee:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        fee_quantity:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        granted_import:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        interest_import:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        lack_quantity:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        periodicity_type:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        treasury_prevision_loan_id:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        devolution_import:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        cancelation_import:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        expected_date:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        policy_name:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        fullcredit:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        creditArranged:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        expirationDate:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        tax:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'min', message: 'VALIDATIONS.MINIMUN_1' },
            { type: 'max', message: 'VALIDATIONS.MAXIMUN_100' }   
        ],
        SettlementType:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'min', message: 'VALIDATIONS.MINIMUN_2' },
            { type: 'max', message: 'VALIDATIONS.MAXIMUN_30' }    
        ],
        option:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        date:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        year:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        treasury_prevision_regime_type_tax_id:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            
        ],
        treasury_prevision_liquidation_type_tax_id:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
           
        ],
        monDEvol:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
          
        ],
        treasury_prevision_liquidation_type_irpf_id:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            
        ],
        sendTo: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'email', message: 'VALIDATIONS.IT_MUST_BE_IN_THE_FORMAT_ABCD' },
            { type: 'pattern', message: 'VALIDATIONS.INVALID_FORMAT' },
        ],
        treasury_prevision_periodicity_type_id:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        split_payments_to:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        first_payment_date:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        granName:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }
        ],
        ExpectedDate:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }
        ],
        settlementDate:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }
        ],
        society_tax_make_payments_account:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }
        ],
        conceptId:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        tax_percent:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        payment_deadline_date:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        description:[
            { type: 'maxlength', message: 'VALIDATIONS.MAXIMUN_255_CHARACTERS' },
        ]
    };

    getTreasuryMessages() {
        return this.treasuryMessages;
    }
}
