import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BackendService } from '@finergia-core/backend';
import { ToastService } from '@finergia-core/shared';
import { stateMeFacade } from '@finergia-core/state-me';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subject, take, takeUntil } from 'rxjs';
import { ChangeCurrencyComponent } from '../modals/change-currency/change-currency.component';
import { ChangeLanguageComponent } from '../modals/change-language/change-language.component';
import { productFruits } from 'product-fruits';

declare var $: any;

declare global {
  interface Window { productFruits: any; }
}
type PfWindow = {
  productFruits: any;
}
@Component({
  selector: 'finergia-core-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  me: any;
  image = '';
  //company_switch_list: any = []
  company_switch_filter: any = [];

  @Input('showMenu2') showMenu2: boolean = false;

  @Input('showSuscription') showSuscription: boolean = false;

  private unsubscribe$ = new Subject<void>();

  loaded = false;

  constructor(
    private facade: stateMeFacade,
    private backend: BackendService,
    private toast: ToastService,
    private changeDetector: ChangeDetectorRef,
    private translate: TranslateService,
    private modal: NgbModal,
    private router: Router) { }

  ngOnInit(): void {
    this.facade.me$.pipe(takeUntil(this.unsubscribe$)).subscribe((data: any)=>{
      if(data && data.email){
        this.me = data;

        console.log(this.me, 'show me')

        if(!this.loaded) {

          this.loadPf();
        }
        

        this.company_switch_filter = data.company_switch_list;

        this.image = data.url_image ? data.url_image + '?'+ this.timeNumber() : '';
        this.changeDetector.detectChanges();
      }
    })
  }

  ngOnDestroy(): void {
      console.log('destroy');
      this.loaded = false;
      window.location.reload();
  }
  timeNumber(){
    return new Date().getTime();
  }

  getCurrentLanguage(){
      return this.translate.currentLang;
  }

  changeLanguage(){
    const modal = this.modal.open(ChangeLanguageComponent, {
      backdrop: 'static',
      size:'md',
      //backdropClass: 'customBackdrop',
      centered: true,
    });

    console.log( this.translate.currentLang)

    modal.componentInstance.languageSelected = this.translate.currentLang;

    modal.result.then(
        (data) => {
          if (data) {
            console.log(data,'aceptando la sincronización y actualizar datos')
          }
        },
        (error) => {
          console.log('Error')
        },
    );
  }

  changeCurrency(currencyId: any){

    const modal = this.modal.open(ChangeCurrencyComponent, {
      backdrop: 'static',
      size:'md',
      //backdropClass: 'customBackdrop',
      centered: true,
    });



    modal.componentInstance.cuerrencySelected = currencyId.id;

    modal.result.then(
        (data) => {
          if (data) {

          }
        },
        (error) => {
          console.log('Error')
        },
    );
  }

  switchCompany(company: any){
    if(this.me.company.id === company.id){
      return;
    }
    this.backend.post('me/company_switch', {
      company_id: company.id
    }).pipe(take(1)).subscribe(()=>{
      this.router.navigateByUrl('').then(()=>{
        window.location.reload();
      });
    },(error: any) => {
      this.toast.displayHTTPErrorToast(error.status, error.error.error, this.translate.instant('GENERAL.ACCEPT'));
    })

  }

  logout(){
    this.facade.logout();
    this.router.navigateByUrl('/login');
  }

  filterCompany(filter: any){
    let value = filter.target.value;

    if (value && value.trim() !== '' && value.length > 2) {

        this.company_switch_filter = this.me.company_switch_list.filter((item: any) => {

          return (item.commercial_name.toLowerCase().indexOf(value.toLowerCase()) > -1  );
        });
      } else {

        this.company_switch_filter = this.me.company_switch_list
      }
  }
  hoverMenu() {
    if($('.app').hasClass('sidenav-toggled')) {
      $('.app').removeClass('sidenav-toggled')
    } else {
      $('.app').addClass('sidenav-toggled')
    }
  }
  loadPf = () => {

    // console.log(this.me.email);
    productFruits.init('IZeCT2NwW3zhCfeu', 'es', { username: this.me.email, email: this.me.email, firstname: this.me.name }, { disableLocationChangeDetection: false });
    this.loaded = true;
      productFruits.safeExec($pf => {
          // console.log($pf);
      })
  }

}
