export class UserClientsMessages {
    userCliets_messages = {
        name: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            { type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_200_CHARACTERS'}
        ],
        last_name: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            { type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_200_CHARACTERS'}
        ],
        identification_document: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'minlength', message: 'VALIDATIONS.MINIMUN_5_CHARACTERS'},
            { type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_20_CHARACTERS'},
            { type: 'invalidCompanyId', message: 'VALIDATIONS.WRONG_CIF_NIE_OR_NIF' }
        ],
      
        phone: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            { type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_20_CHARACTERS'},
            { type: 'invalidPhone', message: 'VALIDATIONS.INVALID_PHONE_IN_SPAIN' },
        ],
        
        email: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'pattern', message: 'VALIDATIONS.INVALID_FORMAT' },
            { type: 'email', message: 'VALIDATIONS.IT_MUST_BE_IN_THE_FORMAT_ABCD' },
        ],
        password: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'invalid', message: 'VALIDATIONS.WRONG_FIELD' },
            { type: 'minlength', message: 'VALIDATIONS.MINIMUN_8_CHARACTERS' },
        ],
        password_confirmation: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'confirmar', message: 'VALIDATIONS.PASSWORDS_DO_NOT_MATCH' },
        ],
        profile_id: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
    };

    getUserClientsMessages() {
        return this.userCliets_messages;
    }
}
