import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { menu } from './state-me.reducer';

export enum LoginActionType {
  LOGIN = '[StateMe] login',
  LOGIN_SUCCESS = '[StateMe] LOGIN_SUCCESS',
  LOGIN_FAIL = '[StateMe] LOGIN_FAIL',
  LOGOUT = '[StateMe] LOGOUT',
  LOAD_ME = '[StateMe] LOAD_ME',
  LOAD_ME_SUCCESS = '[StateMe] LOAD_ME_SUCCESS',
  LOAD_ME_FAIL = '[StateMe] LOAD_ME_FAIL',
  LOAD_OF_LOCALSTORAGE = '[StateMe] LOAD_OF_LOCALSTORAGE',
  UPDATE_ME = '[StateMe] UPDATE_ME',
  UPDATE_ME_SUCCESS = '[StateMe] UPDATE_ME_SUCCESS',
  UPDATE_ME_FAIL = '[StateMe] UPDATE_ME_FAIL',
  UPDATE_IMAGE = '[StateMe] UPDATE_IMAGE',
  UPDATE_IMAGE_SUCCESS = '[StateMe] UPDATE_IMAGE_SUCCESS',
  UPDATE_IMAGE_FAIL = '[StateMe] UPDATE_IMAGE_FAIL'
}

export class LoginAction implements Action {
  readonly type = LoginActionType.LOGIN;
  constructor(public payload: { login: any }) {}
}

export class LoginSuccessAction implements Action {
  readonly type = LoginActionType.LOGIN_SUCCESS;
  constructor(public payload: { login: any }) {}
}

export class LoginFailAction implements Action {
  readonly type = LoginActionType.LOGIN_FAIL;
  constructor(public payload: { error: any }) {}
}

export class LoadMeAction implements Action {
  readonly type = LoginActionType.LOAD_ME;
  constructor(){}
}

export class LoadMeSuccessAction implements Action {
  readonly type = LoginActionType.LOAD_ME_SUCCESS;
  constructor (public payload: {
    name: string,
    last_name: string,
    email: string,
    menu: menu[],
    company: any,
    identification_document: string,
    phone: string,
    url_image: string
    profile: {
      id: number,
      name: string
    },
    profesional_menu: menu[],
    company_switch_list: any[];
    company_preference: any;
    demo_left_days: number;
    company_subscription_status: string;
    use_subscription: boolean;
    use_profiles_admin: boolean;
    company_parent: any;
  }){}

}

export class LoadMeFailAction implements Action {
  readonly type = LoginActionType.LOAD_ME_FAIL;
  constructor(public payload: { error: any }) {}
}

export class LogoutAction implements Action {
  readonly type = LoginActionType.LOGOUT;
}

export class loadOfLocalStorageAction implements Action {
  readonly type = LoginActionType.LOAD_OF_LOCALSTORAGE;
  constructor(){}
}


export class UpdateMeAction implements Action {
  readonly type = LoginActionType.UPDATE_ME;
  constructor(public payload: {
    name: string,
    last_name: string,
    phone: string,
    identification_document: string,
    email: string,
    image?: string;
   }){}
}

export class UpdateMeSuccessAction implements Action {
  readonly type = LoginActionType.UPDATE_ME_SUCCESS;
  constructor(public payload: {
    name: string,
    last_name: string,
    phone: string,
    identification_document: string,
    email: string,
    url_image: string
   }){}
}

export class UpdateMeFailAction implements Action {
  readonly type = LoginActionType.UPDATE_ME_FAIL;
  constructor(public payload: { error: any }) {}
}

export class UpdateImageAction implements Action {
  readonly type = LoginActionType.UPDATE_IMAGE;
  constructor(public payload: {
    image: string;
   }){}
}

export class UpdateImageSuccessAction implements Action {
  readonly type = LoginActionType.UPDATE_IMAGE_SUCCESS;
  constructor(public payload: {
    image: string;
   }){}
}

export class UpdateImageFailAction implements Action {
  readonly type = LoginActionType.UPDATE_IMAGE_FAIL;
  constructor(public payload: { error: any }) {}
}

export type StateMeActions = LoginAction
| LoginSuccessAction
| LoginFailAction
| LogoutAction
| LoadMeAction
| LoadMeSuccessAction
| LoadMeFailAction
| loadOfLocalStorageAction
| UpdateMeAction
| UpdateMeSuccessAction
| UpdateMeFailAction
| UpdateImageAction
| UpdateImageSuccessAction
| UpdateImageFailAction;
