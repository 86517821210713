import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BackendService } from '@finergia-core/backend';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs';

@Component({
  selector: 'finergia-core-phenergy-contract',
  templateUrl: './phenergy-contract.component.html',
  styleUrls: ['./phenergy-contract.component.scss']
})
export class PhenergyContractComponent implements OnInit {
  
  templateGeneralCondition: any = '';
  etiqueta: string = '';

  constructor(
    public activeModal: NgbActiveModal,
    private backend: BackendService
    ) { }

  ngOnInit() {

    switch (this.etiqueta ) {

      case 'PRIVACY_POLICY':
        this.getPrivacyPolicy();
        break;
    
      default:
        this.getGeneralCondition();
        break;
        
    }

  }

  getGeneralCondition(){

    this.backend.get_client('template_documentation/8').pipe(take(1)).subscribe(({data})=>{

      this.templateGeneralCondition = data;

    });

  }

  getPrivacyPolicy(){

    this.backend.get_client('template_documentation/9').pipe(take(1)).subscribe(({data})=>{

      this.templateGeneralCondition = data;

    });

  }

}
