
export class GeneralAccountingMessages {
    generalAccountingMessages = {
        element: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_255_CHARACTER'}
        ],
        level: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_255_CHARACTER'}
        ],
        account_number:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_255_CHARACTER'}
        ],
        account_description:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_255_CHARACTER'}
        ]
        
    };

    getGeneralAccountingMessages() {
        return this.generalAccountingMessages;
    }
}
