<div class="app-header header sticky" [ngClass]="{ 'position-inherit': showMenu2, 'pl-0': showMenu2 , 'margin-show': showSuscription && !showMenu2} ">


    <div class="container-fluid main-container ">
        <div class="d-flex ">
            <a aria-label="Hide Sidebar" data-bs-toggle="sidebar" style="z-index: 999;" (click)="hoverMenu()" *ngIf="!showMenu2">
                <img src="assets/icon/app-sidebar-toggle.svg " class="dropdown-img ">
            </a>


            <!-- sidebar-toggle-->
            <a class="logo-horizontal ">
                <img src="assets/images/brand/logo.png" class="header-brand-img desktop-logo" alt="logo">
                <img src="assets/images/brand/logo-3.png" class="header-brand-img light-logo1" alt="logo">
            </a>
            <ng-container *ngIf="showMenu2 && me && me.profesional_menu && me.profesional_menu.length> 0">
                <ng-container *ngFor="let menu of me.profesional_menu">
                    <div class="main-header-center ms-3 d-none d-lg-block" *ngIf="!menu.childrens">
                        <a class="nav-link d-flex" [routerLink]="menu.route" routerLinkActive="active">
                                {{ menu.name }}
                            </a>
                    </div>

                    <ng-container *ngIf="menu.childrens && menu.childrens.length > 0">
                        <div class="main-header-center ms-3 d-none d-lg-block">
                            <a class="nav-link d-flex" data-bs-toggle="dropdown">
                                    {{ menu.name }}  <img class="polygon" src="assets/icon/polygon.svg" alt="polygon">
                                </a>
                            <ul class="dropdown-menu dropdown-menu-1" role="menu">
                                <li><a [routerLink]="submenu.route" *ngFor="let submenu of menu.childrens">{{ submenu.name }}</a></li>
                            </ul>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>

            <div class="d-flex order-lg-2 ms-auto header-right-icons">
                <div class="navbar navbar-collapse responsive-navbar p-0">
                    <button class="navbar-toggler navresponsive-toggler d-lg-none ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent-4" aria-controls="navbarSupportedContent-4" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon fe fe-more-vertical"></span>
                        </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent-4">
                        <div class="d-flex order-lg-2">
                            <!-- COUNTRY -->
                            <div class="d-flex country">
                                <a class="nav-link icon text-center" (click)="changeLanguage()">
                                    <img src="assets/icon/en.svg" *ngIf="getCurrentLanguage() === 'en'">
                                    <img src="assets/icon/es.svg" *ngIf="getCurrentLanguage() === 'es'">
                                </a>
                            </div>
                            <!-- currency -->
                            <div class="d-flex country" *ngIf="me?.company_preference?.currency?.name">
                                <a class="nav-link icon text-center title-currency" [title]="me.company_preference.currency.name" (click)="changeCurrency(me.company_preference.currency)">
                                        {{me.company_preference.currency.symbol}}
                                    </a>
                            </div>
                            <div class="d-flex notifications">
                                <a class="nav-link icon" [routerLink]="'notifications'">
                                    <img src="assets/icon/bell.svg" alt="bell">
                                </a>
                            </div>
                            <div class="dropdown  d-lg-none d-flex" *ngIf="me && me.profesional_menu && me.profesional_menu.length > 0">
                                <a class="dropdown-item point hover-special" data-bs-toggle="dropdown" class="nav-link leading-none d-flex">
                                    <img src="assets/icon/bar-top.svg" class="dropdown-icon icon-panel-2">
                                </a>
                                <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow menu-profile">
                                    <ul>
                                        <ng-container *ngFor="let menu of me.profesional_menu">
                                            <li class="pl-2 pointer" [routerLink]="menu.route" *ngIf="!menu.childrens">
                                                <a class="dropdown-item point">
                                                        {{ menu.name }}
                                                    </a>
                                            </li>
                                            <ng-container *ngIf="menu && menu.childrens && menu.childrens.length > 0">

                                                <li class="sub-slide is-expanded">
                                                    <a class="dropdown-item point" data-bs-toggle="sub-slide">
                                                        <span class="sub-side-menu__label">{{ menu.name }}</span>
                                                        <i class="sub-angle fe fe-chevron-right"></i></a>
                                                    <ul class="sub-slide-menu open" style="display: block;">
                                                        <li>
                                                            <a class="sub-slide-item" [routerLink]="submenu.route" *ngFor="let submenu of menu.childrens">
                                                                    {{submenu.name}}
                                                                </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ng-container>

                                        </ng-container>


                                    </ul>

                                </div>
                            </div>
                            <!-- SIDE-MENU -->
                            <div class="dropdown d-flex profile-1">
                                <a href="javascript:void(0)" *ngIf="me" data-bs-toggle="dropdown" class="nav-link profile-complete leading-none d-flex">
                                    <img [src]="image != '' ? image : 'assets/images/users/21.jpg'" alt="profile-user" class="avatar  profile-user cover-image">
                                    <div class="profile-data" *ngIf="me && me.company">
                                        <span class="profile-name">{{ (me.name ? (me.name | slice:0:10) : '')  + ' ' + (me.last_name ? (me.last_name | slice:0:10) : '')  }}</span>
                                        <span class="profile-company" [title]="me.company.commercial_name"> {{ me.company.commercial_name.length > 20 ? ((me.company.commercial_name | slice:0:20 ) + '...') : me.company.commercial_name }} </span>
                                    </div>
                                    <div style="flex: 0.5;">
                                        <img src="assets/icon/polygon.svg" class="dropdown-img">
                                    </div>
                                </a>

                                <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow menu-profile">
                                    <ul>
                                        <li class="pl-2 pointer" routerLink="profile">
                                            <a class="dropdown-item point hover-special" routerLinkActive="active">
                                                <img src="assets/icon/user.svg" class=" dropdown-icon"> {{ 'GENERAL.PROFILE' | translate }}
                                            </a>
                                        </li>
                                        <li class="pl-2 pointer" [routerLink]="me.profesional_menu[0].route" *ngIf="me && me.profesional_menu && me.profesional_menu.length > 0">
                                            <a class="dropdown-item point hover-special" routerLinkActive="active">
                                                <img src="assets/icon/bar-top.svg" class=" dropdown-icon">{{ 'GENERAL.PROFESSIONAL_DASHBOARD' | translate }}
                                            </a>
                                        </li>
                                        <ng-container *ngIf="me && me.company_switch_list && me.company_switch_list.length > 0">

                                            <li class="dropdown-divider m-0"></li>

                                            <li>
                                                <div class="form-row main-header-center m-2">
                                                    <input class="form-control" [placeholder]="'GENERAL.SEARCH_FOR' | translate" type="search" (keyup)="filterCompany($event)">

                                                    <button class="btn px-0 pt-2"> <img src="assets/icon/search-gray.svg"
                                                            class="header-brand-img desktop-logo" alt="search"></button>
                                                </div>
                                            </li>

                                            <li class="pl-2">
                                                <div class="row m-0 search">
                                                    <div class="dropdown-item point" [title]="switch.commercial_name" [ngClass]="{'search-select': me && me.company.id === switch.id}" *ngFor="let switch of company_switch_filter" (click)="switchCompany(switch)">
                                                        <i class="dropdown-icon ion-record" *ngIf="me && me.company && me.company.id === switch.id"></i> {{ switch.commercial_name.length > 20 ? ((switch.commercial_name | slice:0:20 ) + '...') : switch.commercial_name
                                                        }}
                                                    </div>
                                                </div>
                                            </li>

                                        </ng-container>
                                        <li class="dropdown-divider m-0"></li>

                                        <li>
                                            <div class="row">
                                                <div class="col-12 text-justify">
                                                    <a class="dropdown-item point hover-special" (click)="logout()">
                                                        <img src="assets/icon/icon-close.svg" class=" dropdown-icon">{{ "GENERAL.LOG_OUT" | translate }}
                                                    </a>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
