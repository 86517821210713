<div class="modal-renew-sync m-5">

    <div class="modal-header ps-xl-1">
        <img class="header-brand-img desktop-logo point img-snyc " src="assets/icon/control-panel/Link_Break.svg">
        <h3 class="modal-title ps-1">{{ 'CONTROL_PANEL.RENEW_SYNCHRONIZATION' | translate }}</h3>

    </div>

    <div class="modal-body">

        <div class="col-lg-12 col-md-12 mt-2">
            <p class="text-p">{{ 'CONTROL_PANEL.TEXT_RENEW_SYNCHRONIZATION' | translate }}</p>

        </div>

        <div class="col-12  text-center mt-6 pb-5">
            <div class="btn-list">
                <button type="button" class="btn btn-outline-secondary  point" (click)="closeModal(false)">
                    {{ 'GENERAL.CANCEL' | translate }}
                </button>
                <button type="button" class="btn btn-secondary  point">
                    
                    <a [href]="data.follow">{{'GENERAL.LINK' | translate }}</a>
                </button>
            </div>

        </div>


    </div>

</div>