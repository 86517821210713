export abstract class UtilData {
    public static LOCALES = [
        'af-NA',
        'af-ZA',
        'ak-GH',
        'am-ET',
        'ar-AE',
        'ar-BH',
        'ar-DJ',
        'ar-DZ',
        'ar-EG',
        'ar-EH',
        'ar-ER',
        'ar-IL',
        'ar-IQ',
        'ar-JO',
        'ar-KM',
        'ar-KW',
        'ar-LB',
        'ar-LY',
        'ar-MA',
        'ar-MR',
        'ar-OM',
        'ar-PS',
        'ar-QA',
        'ar-SA',
        'ar-SD',
        'ar-SO',
        'ar-SS',
        'ar-SY',
        'ar-TD',
        'ar-TN',
        'ar-YE',
        'as-IN',
        'az-AZ',
        'be-BY',
        'bg-BG',
        'bm-ML',
        'bn-BD',
        'bn-IN',
        'bo-CN',
        'bo-IN',
        'br-FR',
        'bs-BA',
        'ca-AD',
        'ca-ES',
        'ca-FR',
        'ca-IT',
        'ce-RU',
        'cs-CZ',
        'cu-RU',
        'cy-GB',
        'da-DK',
        'da-GL',
        'de-AT',
        'de-BE',
        'de-CH',
        'de-DE',
        'de-LI',
        'de-LU',
        'dz-BT',
        'ee-GH',
        'ee-TG',
        'el-CY',
        'el-GR',
        'en-AG',
        'en-AI',
        'en-AS',
        'en-AT',
        'en-AU',
        'en-BB',
        'en-BE',
        'en-BI',
        'en-BM',
        'en-BS',
        'en-BW',
        'en-BZ',
        'en-CA',
        'en-CC',
        'en-CH',
        'en-CK',
        'en-CM',
        'en-CX',
        'en-CY',
        'en-DE',
        'en-DG',
        'en-DK',
        'en-DM',
        'en-ER',
        'en-FI',
        'en-FJ',
        'en-FK',
        'en-FM',
        'en-GB',
        'en-GD',
        'en-GG',
        'en-GH',
        'en-GI',
        'en-GM',
        'en-GU',
        'en-GY',
        'en-HK',
        'en-IE',
        'en-IL',
        'en-IM',
        'en-IN',
        'en-IO',
        'en-JE',
        'en-JM',
        'en-KE',
        'en-KI',
        'en-KN',
        'en-KY',
        'en-LC',
        'en-LR',
        'en-LS',
        'en-MG',
        'en-MH',
        'en-MO',
        'en-MP',
        'en-MS',
        'en-MT',
        'en-MU',
        'en-MW',
        'en-MY',
        'en-NA',
        'en-NF',
        'en-NG',
        'en-NL',
        'en-NR',
        'en-NU',
        'en-NZ',
        'en-PG',
        'en-PH',
        'en-PK',
        'en-PN',
        'en-PR',
        'en-PW',
        'en-RW',
        'en-SB',
        'en-SC',
        'en-SD',
        'en-SE',
        'en-SG',
        'en-SH',
        'en-SI',
        'en-SL',
        'en-SS',
        'en-SX',
        'en-SZ',
        'en-TC',
        'en-TK',
        'en-TO',
        'en-TT',
        'en-TV',
        'en-TZ',
        'en-UG',
        'en-UM',
        'en-US',
        'en-VC',
        'en-VG',
        'en-VI',
        'en-VU',
        'en-WS',
        'en-ZA',
        'en-ZM',
        'en-ZW',
        'es-AR',
        'es-BO',
        'es-CL',
        'es-CO',
        'es-CR',
        'es-CU',
        'es-DO',
        'es-EA',
        'es-EC',
        'es-ES',
        'es-GQ',
        'es-GT',
        'es-HN',
        'es-IC',
        'es-MX',
        'es-NI',
        'es-PA',
        'es-PE',
        'es-PH',
        'es-PR',
        'es-PY',
        'es-SV',
        'es-US',
        'es-UY',
        'es-VE',
        'et-EE',
        'eu-ES',
        'fa-AF',
        'fa-IR',
        'ff-CM',
        'ff-GN',
        'ff-MR',
        'ff-SN',
        'fi-FI',
        'fo-DK',
        'fo-FO',
        'fr-BE',
        'fr-BF',
        'fr-BI',
        'fr-BJ',
        'fr-BL',
        'fr-CA',
        'fr-CD',
        'fr-CF',
        'fr-CG',
        'fr-CH',
        'fr-CI',
        'fr-CM',
        'fr-DJ',
        'fr-DZ',
        'fr-FR',
        'fr-GA',
        'fr-GF',
        'fr-GN',
        'fr-GP',
        'fr-GQ',
        'fr-HT',
        'fr-KM',
        'fr-LU',
        'fr-MA',
        'fr-MC',
        'fr-MF',
        'fr-MG',
        'fr-ML',
        'fr-MQ',
        'fr-MR',
        'fr-MU',
        'fr-NC',
        'fr-NE',
        'fr-PF',
        'fr-PM',
        'fr-RE',
        'fr-RW',
        'fr-SC',
        'fr-SN',
        'fr-SY',
        'fr-TD',
        'fr-TG',
        'fr-TN',
        'fr-VU',
        'fr-WF',
        'fr-YT',
        'fy-NL',
        'ga-IE',
        'gd-GB',
        'gl-ES',
        'gu-IN',
        'gv-IM',
        'ha-GH',
        'ha-NE',
        'ha-NG',
        'he-IL',
        'hi-IN',
        'hr-BA',
        'hr-HR',
        'hu-HU',
        'hy-AM',
        'id-ID',
        'ig-NG',
        'ii-CN',
        'is-IS',
        'it-CH',
        'it-IT',
        'it-SM',
        'ja-JP',
        'ka-GE',
        'ki-KE',
        'kk-KZ',
        'kl-GL',
        'km-KH',
        'kn-IN',
        'ko-KP',
        'ko-KR',
        'ks-IN',
        'kw-GB',
        'ky-KG',
        'lb-LU',
        'lg-UG',
        'ln-AO',
        'ln-CD',
        'ln-CF',
        'ln-CG',
        'lo-LA',
        'lt-LT',
        'lu-CD',
        'lv-LV',
        'mg-MG',
        'mk-MK',
        'ml-IN',
        'mn-MN',
        'mr-IN',
        'ms-BN',
        'ms-MY',
        'ms-SG',
        'mt-MT',
        'my-MM',
        'nb-NO',
        'nb-SJ',
        'nd-ZW',
        'ne-IN',
        'ne-NP',
        'nl-AW',
        'nl-BE',
        'nl-BQ',
        'nl-CW',
        'nl-NL',
        'nl-SR',
        'nl-SX',
        'nn-NO',
        'om-ET',
        'om-KE',
        'or-IN',
        'os-GE',
        'os-RU',
        'pa-IN',
        'pa-PK',
        'pl-PL',
        'ps-AF',
        'pt-AO',
        'pt-BR',
        'pt-CV',
        'pt-GW',
        'pt-MO',
        'pt-MZ',
        'pt-PT',
        'pt-ST',
        'pt-TL',
        'qu-BO',
        'qu-EC',
        'qu-PE',
        'rm-CH',
        'rn-BI',
        'ro-MD',
        'ro-RO',
        'ru-BY',
        'ru-KG',
        'ru-KZ',
        'ru-MD',
        'ru-RU',
        'ru-UA',
        'rw-RW',
        'se-FI',
        'se-NO',
        'se-SE',
        'sg-CF',
        'si-LK',
        'sk-SK',
        'sl-SI',
        'sn-ZW',
        'so-DJ',
        'so-ET',
        'so-KE',
        'so-SO',
        'sq-AL',
        'sq-MK',
        'sq-XK',
        'sr-BA',
        'sr-ME',
        'sr-RS',
        'sr-XK',
        'sv-AX',
        'sv-FI',
        'sv-SE',
        'sw-CD',
        'sw-KE',
        'sw-TZ',
        'sw-UG',
        'ta-IN',
        'ta-LK',
        'ta-MY',
        'ta-SG',
        'te-IN',
        'th-TH',
        'ti-ER',
        'ti-ET',
        'tk-TM',
        'tl-PH',
        'to-TO',
        'tr-CY',
        'tr-TR',
        'ug-CN',
        'uk-UA',
        'ur-IN',
        'ur-PK',
        'uz-AF',
        'uz-UZ',
        'vi-VN',
        'yo-BJ',
        'yo-NG',
        'zh-CN',
        'zh-HK',
        'zh-MO',
        'zh-SG',
        'zh-TW',
        'zu-ZA',
    ];
    public static COUNTRIES: any = {
        'AF': 'Afghanistan',
        'AX': 'Aland Islands',
        'AL': 'Albania',
        'DZ': 'Algeria',
        'AS': 'American Samoa',
        'AD': 'Andorra',
        'AO': 'Angola',
        'AI': 'Anguilla',
        'AQ': 'Antarctica',
        'AG': 'Antigua and Barbuda',
        'AR': 'Argentina',
        'AM': 'Armenia',
        'AW': 'Aruba',
        'AU': 'Australia',
        'AT': 'Austria',
        'AZ': 'Azerbaijan',
        'BS': 'Bahamas',
        'BH': 'Bahrain',
        'BD': 'Bangladesh',
        'BB': 'Barbados',
        'BY': 'Belarus',
        'BE': 'Belgium',
        'BZ': 'Belize',
        'BJ': 'Benin',
        'BM': 'Bermuda',
        'BT': 'Bhutan',
        'BO': 'Bolivia',
        'BA': 'Bosnia and Herzegovina',
        'BW': 'Botswana',
        'BV': 'Bouvet Island',
        'BR': 'Brazil',
        'IO': 'British Indian Ocean Territory',
        'BN': 'Brunei Darussalam',
        'BG': 'Bulgaria',
        'BF': 'Burkina Faso',
        'BI': 'Burundi',
        'KH': 'Cambodia',
        'CM': 'Cameroon',
        'CA': 'Canada',
        'CV': 'Cape Verde',
        'KY': 'Cayman Islands',
        'CF': 'Central African Republic',
        'TD': 'Chad',
        'CL': 'Chile',
        'CN': 'China',
        'CX': 'Christmas Island',
        'CC': 'Cocos (Keeling) Islands',
        'CO': 'Colombia',
        'KM': 'Comoros',
        'CG': 'Congo',
        'CD': 'Congo, The Democratic Republic of the',
        'CK': 'Cook Islands',
        'CR': 'Costa Rica',
        'CI': 'Cote D Ivoire',
        'HR': 'Croatia',
        'CU': 'Cuba',
        'CY': 'Cyprus',
        'CZ': 'Czech Republic',
        'DK': 'Denmark',
        'DJ': 'Djibouti',
        'DM': 'Dominica',
        'DO': 'Dominican Republic',
        'EC': 'Ecuador',
        'EG': 'Egypt',
        'SV': 'El Salvador',
        'GQ': 'Equatorial Guinea',
        'ER': 'Eritrea',
        'EE': 'Estonia',
        'ET': 'Ethiopia',
        'FK': 'Falkland Islands (Malvinas)',
        'FO': 'Faroe Islands',
        'FJ': 'Fiji',
        'FI': 'Finland',
        'FR': 'France',
        'GF': 'French Guiana',
        'PF': 'French Polynesia',
        'TF': 'French Southern Territories',
        'GA': 'Gabon',
        'GM': 'Gambia',
        'GE': 'Georgia',
        'DE': 'Germany',
        'GH': 'Ghana',
        'GI': 'Gibraltar',
        'GR': 'Greece',
        'GL': 'Greenland',
        'GD': 'Grenada',
        'GP': 'Guadeloupe',
        'GU': 'Guam',
        'GT': 'Guatemala',
        'GG': 'Guernsey',
        'GN': 'Guinea',
        'GW': 'Guinea-Bissau',
        'GY': 'Guyana',
        'HT': 'Haiti',
        'HM': 'Heard Island and Mcdonald Islands',
        'VA': 'Holy See (Vatican City State)',
        'HN': 'Honduras',
        'HK': 'Hong Kong',
        'HU': 'Hungary',
        'IS': 'Iceland',
        'IN': 'India',
        'ID': 'Indonesia',
        'IR': 'Iran, Islamic Republic Of',
        'IQ': 'Iraq',
        'IE': 'Ireland',
        'IM': 'Isle of Man',
        'IL': 'Israel',
        'IT': 'Italy',
        'JM': 'Jamaica',
        'JP': 'Japan',
        'JE': 'Jersey',
        'JO': 'Jordan',
        'KZ': 'Kazakhstan',
        'KE': 'Kenya',
        'KI': 'Kiribati',
        'KR': 'Korea, Republic of',
        'KW': 'Kuwait',
        'KG': 'Kyrgyzstan',
        'LA': 'Lao',
        'LV': 'Latvia',
        'LB': 'Lebanon',
        'LS': 'Lesotho',
        'LR': 'Liberia',
        'LY': 'Libyan Arab Jamahiriya',
        'LI': 'Liechtenstein',
        'LT': 'Lithuania',
        'LU': 'Luxembourg',
        'MO': 'Macao',
        'MK': 'Macedonia, The Former Yugoslav Republic of',
        'MG': 'Madagascar',
        'MW': 'Malawi',
        'MY': 'Malaysia',
        'MV': 'Maldives',
        'ML': 'Mali',
        'MT': 'Malta',
        'MH': 'Marshall Islands',
        'MQ': 'Martinique',
        'MR': 'Mauritania',
        'MU': 'Mauritius',
        'YT': 'Mayotte',
        'MX': 'Mexico',
        'FM': 'Micronesia, Federated States of',
        'MD': 'Moldova, Republic of',
        'MC': 'Monaco',
        'MN': 'Mongolia',
        'ME': 'Montenegro',
        'MS': 'Montserrat',
        'MA': 'Morocco',
        'MZ': 'Mozambique',
        'MM': 'Myanmar',
        'NA': 'Namibia',
        'NR': 'Nauru',
        'NP': 'Nepal',
        'NL': 'Netherlands',
        'AN': 'Netherlands Antilles',
        'NC': 'New Caledonia',
        'NZ': 'New Zealand',
        'NI': 'Nicaragua',
        'NE': 'Niger',
        'NG': 'Nigeria',
        'NU': 'Niue',
        'NF': 'Norfolk Island',
        'MP': 'Northern Mariana Islands',
        'NO': 'Norway',
        'OM': 'Oman',
        'PK': 'Pakistan',
        'PW': 'Palau',
        'PS': 'Palestinian Territory, Occupied',
        'PA': 'Panama',
        'PG': 'Papua New Guinea',
        'PY': 'Paraguay',
        'PE': 'Peru',
        'PH': 'Philippines',
        'PN': 'Pitcairn',
        'PL': 'Poland',
        'PT': 'Portugal',
        'PR': 'Puerto Rico',
        'QA': 'Qatar',
        'RO': 'Romania',
        'RU': 'Russian Federation',
        'SH': 'Saint Helena',
        'KN': 'Saint Kitts and Nevis',
        'LC': 'Saint Lucia',
        'PM': 'Saint Pierre and Miquelon',
        'VC': 'Saint Vincent and the Grenadines',
        'WS': 'Samoa',
        'SM': 'San Marino',
        'ST': 'Sao Tome and Principe',
        'SA': 'Saudi Arabia',
        'SN': 'Senegal',
        'RS': 'Serbia',
        'SC': 'Seychelles',
        'SL': 'Sierra Leone',
        'SG': 'Singapore',
        'SK': 'Slovakia',
        'SI': 'Slovenia',
        'SB': 'Solomon Islands',
        'SO': 'Somalia',
        'ZA': 'South Africa',
        'GS': 'South Georgia and the South Sandwich Islands',
        'ES': 'España',
        'LK': 'Sri Lanka',
        'SD': 'Sudan',
        'SR': 'Suriname',
        'SJ': 'Svalbard and Jan Mayen',
        'SZ': 'Swaziland',
        'SE': 'Sweden',
        'CH': 'Switzerland',
        'SY': 'Syrian Arab Republic',
        'TW': 'Taiwan, Province of China',
        'TJ': 'Tajikistan',
        'TZ': 'Tanzania, United Republic of',
        'TH': 'Thailand',
        'TL': 'Timor-Leste',
        'TG': 'Togo',
        'TK': 'Tokelau',
        'TO': 'Tonga',
        'TT': 'Trinidad and Tobago',
        'TN': 'Tunisia',
        'TR': 'Turkey',
        'TM': 'Turkmenistan',
        'TC': 'Turks and Caicos Islands',
        'TV': 'Tuvalu',
        'UG': 'Uganda',
        'UA': 'Ukraine',
        'AE': 'United Arab Emirates',
        'GB': 'United Kingdom',
        'US': 'United States',
        'UM': 'United States Minor Outlying Islands',
        'UY': 'Uruguay',
        'UZ': 'Uzbekistan',
        'VU': 'Vanuatu',
        'VE': 'Venezuela',
        'VN': 'Viet Nam',
        'VG': 'Virgin Islands, British',
        'VI': 'Virgin Islands, U.S.',
        'WF': 'Wallis and Futuna',
        'EH': 'Western Sahara',
        'YE': 'Yemen',
        'ZM': 'Zambia',
        'ZW': 'Zimbabwe',
    };

    public static PHONE_CODE = [
        { country: 'Heard Island and Mcdonald Islands', code: 61 },
        { country: 'Western Sahara', code: 212 },
        { country: 'Wallis and Futuna', code: 681 },
        { country: 'Virgin Islands, U.S.', code: 1 },
        { country: 'Virgin Islands, British', code: 1284 },
        { country: 'Viet Nam', code: 84 },
        { country: 'Vanuatu', code: 678 },
        { country: 'Uzbekistan', code: 998 },
        { country: 'United States Minor Outlying Islands', code: 246 },
        { country: 'Tuvalu', code: 1 },
        { country: 'Turks and Caicos Islands', code: 688 },
        { country: 'Turkmenistan', code: 993 },
        { country: 'Trinida and Tobago', code: 868 },
        { country: 'Tonga', code: 676 },
        { country: 'Tokelau', code: 690 },
        { country: 'Togo', code: 228 },
        { country: 'Timor-leste', code: 670 },
        { country: 'Tanzania, United Republic of', code: 255 },
        { country: 'Tajikistan', code: 992 },
        { country: 'Swaziland', code: 268 },
        { country: 'Svalbard and Jan Mayen', code: 47 },
        { country: 'Suriname', code: 597 },
        { country: 'South Georgia and the South Sandwich Islands', code: 500 },
        { country: 'Solomon Island', code: 677 },
        { country: 'Sierra Leone', code: 232 },
        { country: 'Seychelles', code: 248 },
        { country: 'Serbia', code: 381 },
        { country: 'Sao Tome and Principe', code: 239 },
        { country: 'San Marino', code: 378 },
        { country: 'Samoa', code: 685 },
        { country: 'Saint Vincent and the Grenadines', code: 1784 },
        { country: 'Saint Pierre and Miquelon', code: 508 },
        { country: 'Saint Lucia', code: 1758 },
        { country: 'Saint Kitts and Nevis', code: 1869 },
        { country: 'Saint Helena', code: 290 },
        { country: 'Pitcairn', code: 64 },
        { country: 'Papua New Guinea', code: 675 },
        { country: 'Palestinian Territory, Occupied', code: 970 },
        { country: 'Palau', code: 680 },
        { country: 'Oman', code: 968 },
        { country: 'Northern Mariana Islands', code: 1 },
        { country: 'Norfolk Island', code: 672 },
        { country: 'Niue', code: 683 },
        { country: 'Niger', code: 227 },
        { country: 'New Caledonia', code: 687 },
        { country: 'Netherlands Antilles', code: 599 },
        { country: 'Nauru', code: 674 },
        { country: 'Myanmar', code: 95 },
        { country: 'Montserrat', code: 1 },
        { country: 'Montenegro', code: 382 },
        { country: 'Micronesia, Federated States of', code: 691 },
        { country: 'Mayotte', code: 262 },
        { country: 'Mauritius', code: 230 },
        { country: 'Marshall Islands', code: 692 },
        { country: 'Mali', code: 223 },
        { country: 'Maldives', code: 960 },
        { country: 'Malawi', code: 265 },
        { country: 'Macao', code: 853 },
        { country: 'Libyan Arab Jamahiriya', code: 218 },
        { country: 'Lesotho', code: 266 },
        { country: 'Kyrgyzstan', code: 996 },
        { country: 'Kiribati', code: 686 },
        { country: 'Kazakhstan', code: 7 },
        { country: 'Jersey', code: 44 },
        { country: 'Isle of Man', code: 44 },
        { country: 'Guyana', code: 245 },
        { country: 'Guinea-Bissau', code: 225 },
        { country: 'Guinea', code: 224 },
        { country: 'Guernsey', code: 44 },
        { country: 'Guam', code: 1 },
        { country: 'Guadeloupe', code: 590 },
        { country: 'Grenada', code: 1473 },
        { country: 'Ghana', code: 233 },
        { country: 'Georgia', code: 995 },
        { country: 'Gambia', code: 220 },
        { country: 'Gabon', code: 241 },
        { country: 'French Southern Territories', code: 689 },
        { country: 'French Polynesia', code: 689 },
        { country: 'French Guiana', code: 594 },
        { country: 'Fiji', code: 679 },
        { country: 'Faroe Islands', code: 298 },
        { country: 'Falkland Islands (Malvinas)', code: 500 },
        { country: 'Eritrea', code: 291 },
        { country: 'Dominica', code: 767 },
        { country: 'Djibouti', code: 253 },
        { country: 'Cook Islands', code: 682 },
        { country: 'Comoros', code: 269 },
        { country: 'Cocos (Keeling) Islands', code: 61 },
        { country: 'Christmas Island', code: 61 },
        { country: 'Chad', code: 235 },
        { country: 'Cayman Islands', code: 1345 },
        { country: 'Burundi', code: 257 },
        { country: 'Burkina Faso', code: 226 },
        { country: 'Brunei Darussalam', code: 673 },
        { country: 'British Indian Ocean Territory', code: 246 },
        { country: 'Bouvet Island', code: 47 },
        { country: 'Botswana', code: 267 },
        { country: 'Bhutan', code: 975 },
        { country: 'Bermuda', code: 1441 },
        { country: 'Benin', code: 229 },
        { country: 'Belize', code: 501 },
        { country: 'Belarus', code: 375 },
        { country: 'Barbados', code: 1246 },
        { country: 'Bahamas', code: 1242 },
        { country: 'Azerbaijan', code: 997 },
        { country: 'Aruba', code: 297 },
        { country: 'Antigua and Barbuda', code: 1268 },
        { country: 'Antarctica', code: 672 },
        { country: 'Anguilla', code: 1264 },
        { country: 'American Samoa', code: 684 },
        { country: 'Aland Islands', code: 358 },
        { country: 'Afghanistan', code: 93 },
        { country: 'Albania', code: 355 },
        { country: 'Germany', code: 49 },
        { country: 'Andorra', code: 376 },
        { country: 'Angola', code: 244 },
        { country: 'Saudi Arabia', code: 966 },
        { country: 'Algeria', code: 213 },
        { country: 'Argentina', code: 54 },
        { country: 'Armenia', code: 374 },
        { country: 'Australia', code: 61 },
        { country: 'Austria', code: 43 },
        { country: 'Bahrain', code: 973 },
        { country: 'Bangladesh', code: 880 },
        { country: 'Belgium', code: 32 },
        { country: 'Bolivia', code: 591 },
        { country: 'Bosnia and Herzegovina', code: 387 },
        { country: 'Brazil', code: 55 },
        { country: 'Bulgaria', code: 359 },
        { country: 'Cape Verde', code: 238 },
        { country: 'Cambodia', code: 855 },
        { country: 'Cameroon', code: 237 },
        { country: 'Canada', code: 1 },
        { country: 'Central African Republic', code: 236 },
        { country: 'Czech Republic', code: 420 },
        { country: 'Chile', code: 56 },
        { country: 'China', code: 86 },
        { country: 'Cyprus', code: 357 },
        { country: 'Colombia', code: 57 },
        { country: 'Congo', code: 242 },
        { country: 'Congo, The Democratic Republic of the', code: 243 },
        { country: 'Korea, Republic of', code: 850 },
        { country: 'Cote D Ivoire', code: 225 },
        { country: 'Costa Rica', code: 506 },
        { country: 'Croatia', code: 385 },
        { country: 'Cuba', code: 53 },
        { country: 'Denmark', code: 45 },
        { country: 'Dominican Republic', code: 1809 },
        { country: 'Ecuador', code: 593 },
        { country: 'Egypt', code: 20 },
        { country: 'El Salvador', code: 503 },
        { country: 'United Arab Emirates', code: 971 },
        { country: 'Slovakia', code: 421 },
        { country: 'Slovenia', code: 386 },
        { country: 'España', code: 34 },
        { country: 'United States', code: 1 },
        { country: 'Estonia', code: 372 },
        { country: 'Ethiopia', code: 251 },
        { country: 'Philippines', code: 63 },
        { country: 'Finland', code: 358 },
        { country: 'France', code: 33 },
        { country: 'Gibraltar', code: 9567 },
        { country: 'Greece', code: 30 },
        { country: 'Greenland', code: 299 },
        { country: 'Guatemala', code: 502 },
        { country: 'Equatorial Guinea', code: 240 },
        { country: 'Haiti', code: 509 },
        { country: 'Honduras', code: 504 },
        { country: 'Hong Kong', code: 852 },
        { country: 'Hungary', code: 36 },
        { country: 'India', code: 91 },
        { country: 'Indonesia', code: 62 },
        { country: 'Iran, Islamic Republic Of', code: 98 },
        { country: 'Iraq', code: 964 },
        { country: 'Ireland', code: 353 },
        { country: 'Iceland', code: 354 },
        { country: 'Israel', code: 972 },
        { country: 'Italy', code: 39 },
        { country: 'Jamaica', code: 1876 },
        { country: 'Japan', code: 81 },
        { country: 'Jordan', code: 962 },
        { country: 'Kenya', code: 254 },
        { country: 'Kuwait', code: 965 },
        { country: 'Lao', code: 856 },
        { country: 'latvia', code: 371 },
        { country: 'Lebanon', code: 961 },
        { country: 'Liberia', code: 231 },
        { country: 'Latvia', code: 218 },
        { country: 'Liechtenstein', code: 41 },
        { country: 'Lithuania', code: 370 },
        { country: 'Luxembourg', code: 352 },
        { country: 'Madagascar', code: 261 },
        { country: 'Malaysia', code: 60 },
        { country: 'Malta', code: 356 },
        { country: 'Morocco', code: 212 },
        { country: 'Martinique', code: 596 },
        { country: 'Mauritania', code: 222 },
        { country: 'Mexico', code: 52 },
        { country: 'Moldova, Republic of', code: 373 },
        { country: 'Monaco', code: 377 },
        { country: 'Mongolia', code: 976 },
        { country: 'Mozambique', code: 258 },
        { country: 'Namibia', code: 264 },
        { country: 'Nepal', code: 977 },
        { country: 'Nicaragua', code: 505 },
        { country: 'Nigeria', code: 234 },
        { country: 'Norway', code: 47 },
        { country: 'New Zealand', code: 64 },
        { country: 'Netherlands', code: 31 },
        { country: 'Pakistan', code: 92 },
        { country: 'Panama', code: 507 },
        { country: 'Paraguay', code: 595 },
        { country: 'Peru', code: 51 },
        { country: 'Poland', code: 48 },
        { country: 'Portugal', code: 351 },
        { country: 'Puerto Rico', code: 1787 },
        { country: 'Qatar', code: 974 },
        { country: 'United Kingdom', code: 44 },
        { country: 'Romania', code: 40 },
        { country: 'Russian Federation', code: 7 },
        { country: 'San Marino', code: 378 },
        { country: 'Senegal', code: 221 },
        { country: 'Singapore', code: 65 },
        { country: 'Syrian Arab Republic', code: 963 },
        { country: 'Somalia', code: 252 },
        { country: 'Sri Lanka', code: 94 },
        { country: 'South Africa', code: 27 },
        { country: 'Sudan', code: 249 },
        { country: 'Sweden', code: 46 },
        { country: 'Switzerland', code: 41 },
        { country: 'Thailand', code: 66 },
        { country: 'Taiwan, Province of China', code: 886 },
        { country: 'anzania, United Republic of', code: 255 },
        { country: 'Tunisia', code: 216 },
        { country: 'Turkey', code: 90 },
        { country: 'Ukraine', code: 380 },
        { country: 'Uganda', code: 256 },
        { country: 'Uruguay', code: 598 },
        { country: 'Holy See (Vatican City State)', code: 39 },
        { country: 'Venezuela', code: 58 },
        { country: 'Vietnam', code: 84 },
        { country: 'Yemen', code: 967 },
        { country: 'Macedonia, The Former Yugoslav Republic of', code: 381 },
        { country: 'Zambia', code: 260 },
        { country: 'Zimbabwe', code: 263 }
    ];


    public static getLocalesFromCountry(countryCode: string) {
        const locales:any = [];
        if (UtilData.COUNTRIES[countryCode]) {
            UtilData.LOCALES.forEach(locale => {
                if (locale.split('-')[1] === countryCode) {
                    locales.push(locale);
                }
            });
        }
        return locales;
    }

    public static getCountryCode(country: string) {
        const code = Object.keys(UtilData.COUNTRIES).find(
            key => UtilData.COUNTRIES[key].toLowerCase() == country.toLowerCase(),
        );
        return code;
    }
    
    public static getCountry() {
        const countrys = Object.keys(UtilData.COUNTRIES).map((key, index) => {
            return UtilData.COUNTRIES[key];
        });

        return countrys.sort();
    }

    public static getCountryWithCode() {
        const countrys = Object.keys(UtilData.COUNTRIES).map((key, index) => {
            return {
                country: UtilData.COUNTRIES[key],
                key
            };
        });

        return countrys.sort();
    }

    public static getCountryPhoneCode() {
        return UtilData.PHONE_CODE;
    }
}
