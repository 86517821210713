import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';

import * as StateClientActions from './state-client.actions';
import * as StateClientFeature from './state-client.reducer';

@Injectable()
export class StateClientEffects {
  

  constructor(private readonly actions$: Actions) {}
}
