<div class="phenergy">

    <div class="modal-header">
        <img src="assets/icon/finergia.svg" class="header-brand-img" alt="finergia">
    </div>

    <div class="modal-body" style="all:unset;">

        <div class="row" *ngIf="templateGeneralCondition">
            <!-- <div class="col-lg-12 col-md-12 message">
                <h3 class="title text-center"> {{ templateGeneralCondition.name }} </h3>
            </div> -->
            <div class="col-lg-12 col-md-12">
                <div class="row-scroll">

                    <div [innerHTML]="templateGeneralCondition.content | safeHtml"></div>

                </div>
            </div>

        </div>

    </div>

    <div class="modal-footer mb-0 m-0 message">
        <p class="text-center mt-8">Finergia y Asociados, S.L. CIF B67593038
            <br> {{'TERM.REGISTERED_OFFICE' | translate }} – Cl. Torreta, nº 8-12 (08810) SANT PERE DE RIBES – BARCEOLONA
        </p>
    </div>

</div>