import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numericFormat'
})
export class NumericFormatPipe implements PipeTransform {

  transform(value: string, showCurrency: boolean = false, currency?: string): string {

    if(value === '0'){
      value = '0.000000';
    }
    value = ''+parseFloat(value  !==null ? value : '0').toFixed(2);

    const company_prefence: string | null = localStorage.getItem('company_preference');

    if(company_prefence != null){
      const preferences = JSON.parse(company_prefence);

      const whole = preferences.numeric_format.regex_whole.split(';');

      const decimal = preferences.numeric_format.regex_decimal;

      const decimalCount = preferences.decimal.quantity;

      const symbol = preferences.currency.symbol;

      const transform = value.split('.');

      return transform[0].replace( new RegExp(whole[0], 'g'), whole[1]) + decimal + (transform[1] ? transform[1].substring(0, decimalCount): '') + ( showCurrency ? ' ' + symbol : '');

    } else {

      return value;

    }


  }

}
