import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromStateClient from './+state/state-client.reducer';
import { StateClientEffects } from './+state/state-client.effects';
import { stateClientFacade } from './+state/state-client.facade'
import { SharedModule } from '@finergia-core/shared';
import { BackendModule } from '@finergia-core/backend';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    BackendModule,
    StoreModule.forFeature(
      fromStateClient.STATE_CLIENT_FEATURE_KEY,
      fromStateClient.reducer
    ),
    EffectsModule.forFeature([StateClientEffects]),
  ],
  providers: [
    stateClientFacade
  ]
})
export class StateClientModule {}
