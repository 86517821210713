import { Injectable } from '@angular/core';
import { stateMeSelector } from '@finergia-core/state-me';
import { Store, select } from '@ngrx/store';
import { LoadMeAction, loadOfLocalStorageAction, LoginAction, LogoutAction, UpdateImageAction, UpdateMeAction } from './state-me.actions';
import { MeState } from './state-me.reducer';
import { StateMeService } from '../state-me.service';

@Injectable()
export class stateMeFacade {
    
    constructor(private store: Store<MeState>, private service: StateMeService) {}

    loaded$ = this.store.pipe(select(stateMeSelector.getStateMeLoaded));
    logged$ = this.store.pipe(select(stateMeSelector.getStateMeLogged));
    me$ = this.store.pipe(select(stateMeSelector.getStateMe));
    menu$ = this.store.pipe(select(stateMeSelector.getStateMenu));

    login(Login: any){
        this.store.dispatch(new LoginAction(Login));
    }

    loadMe(){
        this.store.dispatch(new LoadMeAction());
    }

    loadOfLocalStorage(){
        this.store.dispatch(new loadOfLocalStorageAction());
    }

    updateMe(payload: any){
        this.store.dispatch(new UpdateMeAction(payload));
    }

    logout(){
        this.service.logout();
        this.store.dispatch(new LogoutAction());
    }

    updateImage(image: any){
        this.store.dispatch(new UpdateImageAction({ image }))
    }
}
