import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'finergia-core-general-modal-delete',
  templateUrl: './general-modal-delete.component.html',
  styleUrls: ['./general-modal-delete.component.scss'],
})
export class GeneralModalDeleteComponent implements OnInit {

  title: any;
  text1: any;
  text2: any
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }
  closeModal(value: any){
    this.activeModal.close(value);
  }
}
