import { StateMeActions, LoginActionType } from './state-me.actions';

export const STATE_ME_FEATURE_KEY = 'stateMe';

export interface menu {
  icon?: string;
  id?: number;
  menu_parent_id?: number;
  name: string;
  order?: number
  route?: string;
  childrens?: any[];
  external_link?: boolean;
}
export interface MeState {
  token?: string;
  expires_in?: number;
  refresh_token?: string;
  username?: string;
  loaded: boolean;
  name?: string;
  last_name?: string;
  email?: string;
  menu: menu[];
  profesional_menu?: menu[];
  company?: any;
  identification_document?: string;
  phone?: string;
  url_image?: string;
  profile?: {
    id: number,
    name: string
  };
  logged: boolean;
  company_switch_list?: any[];
  company_preference?: any;
  error?: string | null; // last known error (if any)
  demo_left_days?: number,
  company_subscription_status?: string,
  use_subscription?: boolean;
  use_profiles_admin?: boolean;
  company_parent?: any;
}



export const initialState: MeState = {
  loaded: false,
  logged: false,
  menu: []
}

export function reducer(
  state: MeState = initialState,
  action: StateMeActions,
): MeState {
  switch (action.type) {

      case LoginActionType.LOGIN_SUCCESS: {

        return {
          ...state,
          token: action.payload.login.token,
          expires_in: action.payload.login.expires_in,
          refresh_token: action.payload.login.refresh_token,
          name: action.payload.login.name,
          username: action.payload.login.username,
          last_name: action.payload.login.last_name,
          logged: true
        };
      }

      case LoginActionType.LOGIN: {
        return {
          ...state,
          loaded: false
        }
      }

      case LoginActionType.LOAD_ME_SUCCESS: {

        localStorage.setItem('menu', JSON.stringify(action.payload.menu));
        localStorage.setItem('company_preference', JSON.stringify(action.payload.company_preference));

        return {
          ...state,
          menu: action.payload.menu,
          name: action.payload.name,
          last_name: action.payload.last_name,
          email: action.payload.email,
          company: action.payload.company,
          identification_document: action.payload.identification_document,
          phone: action.payload.phone,
          url_image: action.payload.url_image,
          profile: action.payload.profile,
          profesional_menu: action.payload.profesional_menu,
          company_switch_list: action.payload.company_switch_list,
          company_preference: action.payload.company_preference,
          demo_left_days: action.payload.demo_left_days,
          company_subscription_status: action.payload.company_subscription_status,
          use_subscription: action.payload.use_subscription,
          use_profiles_admin: action.payload.use_profiles_admin,
          company_parent: action.payload.company_parent,
          loaded: true
        }
      }

      case LoginActionType.LOAD_OF_LOCALSTORAGE: {

        const token = localStorage.getItem('token');
        const expires_in = localStorage.getItem('expires_in');
        const refresh_token = localStorage.getItem('refresh_token');
        const username = localStorage.getItem('username');
        const name = localStorage.getItem('name');
        const last_name = localStorage.getItem('last_name');

        return {
          ...state,
          token: token && token != null ? token : '',
          expires_in: expires_in && expires_in != null ? +expires_in : 0,
          refresh_token: refresh_token && refresh_token != null ? refresh_token : '',
          username: username && username != null ? username : '',
          name: name && !(name != null || name != 'null') ? name : '',
          last_name: last_name && !(last_name != null || last_name != 'null') ? last_name : '',
        }
      }

      case LoginActionType.LOGIN_FAIL: {
        return {
          ...state,
          loaded: true,
          logged: false
        }
      }

      case LoginActionType.UPDATE_ME: {
        return {
          ...state,
          loaded: false
        }
      }

      case LoginActionType.UPDATE_ME_SUCCESS: {

        localStorage.setItem('name', action.payload.name);
        localStorage.setItem('last_name', action.payload.last_name);

        return {
          ...state,
          name: action.payload.name,
          last_name: action.payload.last_name,
          phone: action.payload.phone,
          identification_document: action.payload.identification_document,
          email: action.payload.email,
          url_image: action.payload.url_image,
          loaded: true
        }
      }

      case LoginActionType.UPDATE_IMAGE: {
        return {
          ...state,
          loaded: false
        }
      }

      case LoginActionType.UPDATE_IMAGE_SUCCESS: {

        return {
          ...state,
          url_image: action.payload.image,
          loaded: true
        }
      }

      case LoginActionType.UPDATE_ME_FAIL: {

        return {
          ...state,
          loaded: true
        }
      }


      case LoginActionType.LOGOUT: {


        return initialState;
      }

      default:
          return state;
  }
}
