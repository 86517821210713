import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
declare function init_plugins(): any;
@Component({
  selector: 'finergia-core-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'finergia';
  constructor(update: SwUpdate, snackbar: MatSnackBar, private translate: TranslateService) {
    update.versionUpdates.subscribe((data) => {
      console.log(data);
      if(data.type === 'VERSION_READY'){
        update.activateUpdate().then(() => {
          const snack = snackbar.open('Actualización disponible', 'Recargar!');

          snack.onAction().subscribe(() => {
            window.location.reload()
          });
        });

        setInterval(() => {
          update.checkForUpdate().then(() => {
            console.log('service worked!');
          });
        }, 10000);
      }

    })

  }

  ngOnInit(): void {

    this.translate.setDefaultLang('es');
    this.translate.addLangs(['en', 'es']);
    this.translate.use('es');

    init_plugins();

  }
}
