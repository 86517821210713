<div class="confirm m-5">

    <div class="modal-header">
        <h3 class="modal-title">
            <img src="assets/icon/undo-gray.svg" class="header-brand-img" alt="deshacer" *ngIf="icon"> {{ title }}
        </h3>
    </div>

    <div class="modal-body">

        <div class="row">
            <div class="col-lg-12 col-md-12 message">
                {{ message }}
            </div>
        </div>

    </div>

    <div class="modal-footer mt-4">
        <div class="row">
            <div class="col-12 col-sm-6 col-lg-6 text-center mt-1">
                <button [className]="cssStyleCancel" (click)="activeModal.close(false)">{{ cancel }}</button>
            </div>

            <div class="col-12 col-sm-6 col-lg-6 text-center mt-1">
                <ng-container *ngIf="!data">
                    <button [className]="cssStyle" (click)="activeModal.close(true)">{{ accept }}</button>
                </ng-container>
                <ng-container *ngIf="data && data">
                    <button [className]="cssStyle">
                        <a [href]="data.follow">{{ accept }}</a>
                    </button>
                </ng-container>

            </div>
        </div>

    </div>

</div>