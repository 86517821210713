import { Location } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@finergia-core/env/environment';
import { Observable, from, of, throwError } from 'rxjs';
import * as moment from 'moment';
import { take, map, tap, catchError, switchMap, timeout } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoadingService } from '@finergia-core/shared';
import * as _ from 'moment';
declare var $: any;

// const helper = new JwtHelperService();

// const decodedToken = helper.decodeToken(myRawToken);
// const expirationDate = helper.getTokenExpirationDate(myRawToken);
// const isExpired = helper.isTokenExpired(myRawToken);

@Injectable({
    providedIn: 'root',
})
export class BackendService {
    client_id: number = 1;
    grant_type: any = 'password';
    client_secret: any = 'tT96kecNtYVf92dvRfQ1Ikj6sjsx5tKZzaCCpHun';
    timeRefresh: number = environment.sessionTimeOut;
    timeout: number = 1200000;
    constructor(private http: HttpClient, private router: Router, private loading: LoadingService ) {}
    private createBackendUrl(path: string) {
        return Location.joinWithSlash(environment.apiUrl, path);
    }
    
    public get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
        return this.timeoutToken().pipe(
            switchMap(async (isLogged): Promise<any> => {
                if (isLogged) {
                    let response = await this.http
                        .get(this.createBackendUrl(path), {
                            headers: this.headers,
                            params,
                        })
                        .pipe(take(1))
                        .pipe(timeout(this.timeout))
                        .toPromise();
                    return response;
                }
            }),
            catchError(async (error) => {
                if (error.status === 401) {
                    this.Logout();
                }
                return await throwError(error).toPromise();
            }),
        );
    }

    

    

    public get_client(
        path: string,
        params: HttpParams = new HttpParams(),
    ): Observable<any> {
        return this.http.get(this.createBackendUrl(path), {
            headers: this.headers_client,
            params,
        });
    }

    get headersExell(): HttpHeaders {
        const headersConfig = {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            Authorization: `Bearer ${this.getTokenLocalStorage()}`,
        };
        return new HttpHeaders(headersConfig);
    }

    public post(path: string, data: Object = {}): Observable<any> {
        return this.timeoutToken().pipe(
            switchMap(async (isLogged): Promise<any> => {
                if (isLogged) {
                    let response = await this.http
                        .post(this.createBackendUrl(path), JSON.stringify(data), {
                            headers: this.headers,
                        })
                        .pipe(take(1))
                        .pipe(timeout(this.timeout))
                        .toPromise();
                        
                    return response;
                }
            }),
            catchError(async (error) => {
                if (error.status === 401) {
                    this.Logout();
                }
                return await throwError(error).toPromise();
            }),
        );
    }

    public post_file(path: string, data: Object = {}): Observable<any> {
        return this.timeoutToken().pipe(
            switchMap(async (isLogged): Promise<any> => {
                if (isLogged) {
                    let response = await this.http
                        .post(this.createBackendUrl(path), data, {
                            headers: this.headers_file,
                        })
                        .pipe(take(1))
                        .pipe(timeout(this.timeout))
                        .toPromise();
                        
                    return response;
                }
            }),
            catchError(async (error) => {
                if (error.status === 401) {
                    this.Logout();
                }
                return await throwError(error).toPromise();
            }),
        );
    } 

    public post_client(path: string, data: Object = {}): Observable<any> {
        return this.http.post(this.createBackendUrl(path), JSON.stringify(data), {
            headers: this.headers_client,
        });
    }

    public put(path: string, data: Object = {}): Observable<any> {
        return this.timeoutToken().pipe(
            switchMap(async (isLogged): Promise<any> => {
                if (isLogged) {
                    let response = await this.http
                        .put(this.createBackendUrl(path), JSON.stringify(data), {
                            headers: this.headers,
                        })
                        .pipe(take(1))
                        .pipe(timeout(this.timeout))
                        .toPromise();
                    return response;
                }
            }),
            catchError(async (error) => {
                if (error.status === 401) {
                    this.Logout();
                }
                return await throwError(error).toPromise();
            }),
        );
    }

    public patch(path: string, data: Object = {}): Observable<any> {
        return this.timeoutToken().pipe(
            switchMap(async (isLogged): Promise<any> => {
                if (isLogged) {
                    let response = await this.http
                        .patch(this.createBackendUrl(path), JSON.stringify(data), {
                            headers: this.headers,
                        })
                        .pipe(take(1))
                        .pipe(timeout(this.timeout))
                        .toPromise();
                    return response;
                }
            }),
            catchError(async (error) => {
                if (error.status === 401) {
                    this.Logout();
                }
                return await throwError(error).toPromise();
            }),
        );
    }

    public delete(path: string): Observable<any> {
        return this.timeoutToken().pipe(
            switchMap(async (isLogged): Promise<any> => {
                if (isLogged) {
                    let response = await this.http
                        .delete(this.createBackendUrl(path), { headers: this.headers })
                        .pipe(take(1))
                        .pipe(timeout(this.timeout))
                        .toPromise();
                    return response;
                }
            }),
            catchError(async (error) => {
                if (error.status === 401) {
                    this.Logout();
                }
                return await throwError(error).toPromise();
            }),
        );
    }

    getPDF(path: string, params: HttpParams = new HttpParams()) {
        this.showLoading();
        return new Promise((resolve, reject) => {
            this.http
                .get(this.createBackendUrl(path), {
                    headers: this.headersPdf,
                    params,
                    observe: 'response',
                    responseType: 'blob',
                })
                .pipe(timeout(this.timeout))
                .subscribe(
                    (data: any) => {
                        const fileURL = URL.createObjectURL(
                            new Blob([data.body], { type: 'application/pdf' }),
                        );

                        window.open(fileURL, '_blank');
                        this.hideLoading();
                    },
                    (error) => {
                        this.hideLoading();
                        reject(error);
                        console.log(error);
                    },
                );
        });
    }
    
    /* pdf si es metodo post */
    getPDFRouteAssigne(path: string, data:any) : any{
        return new Promise((resolve, reject) => {
            this.http
                .post(this.createBackendUrl(path), {routes:data},{
                   // routes: params,
                    headers: this.headers,
                    //params,
                    observe: 'response',
                    responseType: 'blob',
                })
                .pipe(timeout(this.timeout))
                .subscribe(
                    (data: any) => {
                    
                        const fileURL = URL.createObjectURL(
                            new Blob([data.body], { type: 'application/pdf' }),
                        );

                        window.open(fileURL, '_blank');

                    },
                    (error) => {
                        reject(error);
                        console.log(error);
                    },
                );
        });
    }
    // pdf devolution type post
    getPDFDevolution(path: string, data:any) : any{
        return new Promise((resolve, reject) => {
            this.http
                .post(this.createBackendUrl(path), data,{
                   
                    headers: this.headers,
                   
                    observe: 'response',

                    responseType: 'blob',
                })
                .pipe(timeout(this.timeout))
                .subscribe(
                    (data: any) => {
                    
                        const fileURL = URL.createObjectURL(
                            new Blob([data.body], { type: 'application/pdf' }),
                        );

                        window.open(fileURL, '_blank');
                    },
                    (error) => {
                        reject(error);
                        console.log(error);
                    },
                );
        });
    }
    // metodo personalizado para reporte de analisys de clientes con loading
    getPDFReportFinalcial(path: string, data:any) : any{

        this.showLoading();

        return new Promise((resolve, reject) => {
            this.http
                .post(this.createBackendUrl(path), data,{
                   
                    headers: this.headers,
                   
                    observe: 'response',

                    responseType: 'blob',
                })
                .pipe(timeout(this.timeout))
                .subscribe(
                    (data: any) => {
                    
                        const fileURL = URL.createObjectURL(
                            new Blob([data.body], { type: 'application/pdf' }),
                        );

                        window.open(fileURL, '_blank');

                        this.hideLoading();
                    },
                    (error) => {

                        this.hideLoading();
                        
                        reject(error);
                        console.log(error);
                    },
                );
        });
    }


    //descargar pdf
    getPDFDescargar(path: string) : any{
        return new Promise((resolve, reject) => {
            this.http
                .get(this.createBackendUrl(path),{
                   
                    headers: this.headers,
                   
                    observe: 'response',

                    responseType: 'blob',
                })
                .pipe(timeout(this.timeout))
                .subscribe(
                    (data: any) => {
                    
                       /*  const fileURL = URL.createObjectURL(
                            new Blob([data.body], { type: 'application/pdf' }),
                        );

                        window.open(fileURL, '_blank'); */
                        let downloadLink = document.createElement('a');

                        downloadLink.href = window.URL.createObjectURL(new Blob([data.body], { type: 'application/pdf' }));
                        

                        downloadLink.setAttribute('download', `SheetRoute_.pdf`);

                        document.body.appendChild(downloadLink);

                        downloadLink.click();

                    },
                    (error) => {
                        reject(error);
                        console.log(error);
                    },
                );
        });
    }
    

    public postFile(path: string, data: any): Observable<any> {
        return this.timeoutToken().pipe(
            switchMap(async (isLogged): Promise<any> => {
                if (isLogged) {
                    let response = await this.http
                        .post(this.createBackendUrl(path), data, {
                            headers: this.headersExcel,
                        })
                        .pipe(take(1))
                        .pipe(timeout(this.timeout))
                        .toPromise();
                    return response;
                }
            }),
            catchError(async (error) => {
                return await throwError(error).toPromise();
            }),
        );
    }

    get headersPdf(): HttpHeaders {
        const headersConfig = {
            'Content-Type': 'application/pdf',
            Accept:'application/json',
            Authorization: `Bearer ${this.getTokenLocalStorage()}`,
        };
        return new HttpHeaders(headersConfig);
    }

    get headers(): HttpHeaders {
        const headersConfig = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${this.getTokenLocalStorage()}`,
        };
        return new HttpHeaders(headersConfig);
    }

    get headers_file(): HttpHeaders {
        const headersConfig = {
            Authorization: `Bearer ${this.getTokenLocalStorage()}`,
        };
        return new HttpHeaders(headersConfig);
    }

    get headers_client(): HttpHeaders {
        const headersConfig = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };
        return new HttpHeaders(headersConfig);
    }

    get headersExcel(): HttpHeaders {
        const headersConfig = {
            // 'Content-Type': 'multipart/form-data',
            enctype: 'multipart/form-data',
            Accept: 'application/json',
            Authorization: `Bearer ${this.getTokenLocalStorage()}`,
        };
        return new HttpHeaders(headersConfig);
    }

    getTokenLocalStorage(): string {
        const value = localStorage.getItem('token');
        return value && value != null  ? value : '';
    }

    timeoutToken() {
        return from(
            new Promise((resolve, reject) => {
                
                if (this.getTokenLocalStorage()) {
                    let payload = JSON.parse(
                        atob( this.getTokenLocalStorage().split('.')[1]),
                    );
                    let tokenExp: any = moment(payload.exp * 1000);
                    let ahora: any = moment(new Date());

                    let diff = Math.round(tokenExp.diff(ahora) / 1000);
                    if (diff < this.timeRefresh) {
                        
                        this.get_client_token()
                            .then((data) => {
                                resolve(data);
                            })
                            .catch((error) => reject(error));
                    } else {
                        resolve(true);
                    }
                } else {
                    this.get_client_token()
                        .then((data) => {
                            resolve(data);
                        })
                        .catch((error) => reject(error));
                }
            }),
        );
    }

    get_client_token() {
        return new Promise((resolve, reject) => {
            this.post_client('oauth/token_admin', {
                client_id: this.client_id,

                client_secret: this.client_secret,

                grant_type: 'refresh_token',

                refresh_token: localStorage.getItem('refresh_token'),
            }).subscribe(
                (data) => {
                    localStorage.setItem('token', data.access_token);
                    localStorage.setItem('refresh_token', data.refresh_token);

                    resolve(data);
                },
                (error) => {
                    reject(error);
                },
            );
        });
    }

    getExcel(path: string, params: HttpParams = new HttpParams()) {
        return new Promise((resolve, reject) => {
            this.http
                .get(this.createBackendUrl(path), {
                    headers: this.headersExell,
                    params,
                    observe: 'response',
                    responseType: 'blob',
                })
                .pipe(timeout(this.timeout))
                .subscribe(
                    (data: any) => {

                        // para descargar
                       
                        let downloadLink = document.createElement('a');

                        downloadLink.href = window.URL.createObjectURL(new Blob([data.body], { type: 'application/octet-stream' }));
                        if (data)

                        downloadLink.setAttribute('download', `FincalcialAnalycisPreambulo_${new Date()}_.xlsx`);

                        document.body.appendChild(downloadLink);

                        downloadLink.click();

                        resolve(true);

                       // resolve(fileURL);
                        

                    },
                    (error) => {
                        reject(error);
                        console.log(error);
                    },
                );
        });
    }

    postExcel(path: string, data: any, params: HttpParams = new HttpParams()) {
        return new Promise((resolve, reject) => {
            this.http
                .post(this.createBackendUrl(path), data, {
                    headers: this.headers,
                    params,
                    observe: 'response',
                    responseType: 'blob',
                })
                .subscribe(
                    (data: any) => {

                        // para descargar
                       
                        let downloadLink = document.createElement('a');

                        downloadLink.href = window.URL.createObjectURL(new Blob([data.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
                        if (data)

                        downloadLink.setAttribute('download', `reporte-${moment().format('YYYY-MM-DD')}.xlsx`);

                        document.body.appendChild(downloadLink);

                        downloadLink.click();

                       resolve(true);
                        

                    },
                    (error) => {
                        reject(error);
                        console.log(error);
                    },
                );
        });
    }

    Logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('username');
        localStorage.removeItem('company');
        localStorage.removeItem('roles');
        this.router.navigateByUrl('/login');
    }


    getFile(path: string, type: string, name: string , params: HttpParams = new HttpParams()) {
        return new Promise((resolve, reject) => {
            this.http
                .get(this.createBackendUrl(path), {
                    headers: this.headersPdf,
                    params,
                    observe: 'response',
                    responseType: 'blob',
                })
                .pipe(timeout(this.timeout))
                .subscribe(
                    (data: any) => {
                        const fileURL = URL.createObjectURL(
                            new Blob([data.body], { type }),
                        );

                        const a = document.createElement('a')
                        a.href = fileURL;
                        a.download = name;
                        a.click();
                        URL.revokeObjectURL(data);
                    },
                    (error) => {
                        reject(error);
                        console.log(error);
                    },
                );
        });
    }

    showLoading() {

        $('.overlay-loading').fadeIn().css('display', 'table');
    
      }
    
      hideLoading() {
    
        $('.overlay-loading').fadeOut();
    
      }

    

}
