import { Component, OnInit } from '@angular/core';
import { BackendService } from '@finergia-core/backend';
import { ToastService } from '@finergia-core/shared';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';

@Component({
  selector: 'finergia-core-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.css'],
})
export class ChangeLanguageComponent implements OnInit {
  

  languageSelected: string | undefined;
  
  languages: any = [];

  loadingLanguage: string ='loading';

  constructor(private backend: BackendService,
    private translate: TranslateService,
    private activeModal: NgbActiveModal,
    private toast: ToastService) {}

  ngOnInit(): void {
    this.getlanguage()
  }

  close(){
    this.activeModal.close();
  }

  getlanguage(){

    this.loadingLanguage ='load';

    this.backend.get('language').pipe(take(1)).subscribe((data: any) => {

      this.languages = data.data;
      this.loadingLanguage ='succes';
   
    }, (error: any) => {

      this.loadingLanguage ='succes';
      this.toast.displayHTTPErrorToast(error.status, error.error.error);
     
    });

  }

  changeLanguage(language: any){
    
    this.backend.post('company_preference', {
      language_id: language.id
    }).pipe(take(1)).subscribe((data)=>{
      this.translate.setDefaultLang(language.abbreviation);
      this.translate.use(language.abbreviation);
      location.reload();
      this.activeModal.close();
    }, error => {
      this.toast.displayHTTPErrorToast(error.status, error.error.error, this.translate.instant('GENERAL.ACCEPT'));
    })
  }
}
