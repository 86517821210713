import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  STATE_CLIENT_FEATURE_KEY,
  Client
} from './state-client.reducer';

// Lookup the 'StateRegister' feature state managed by NgRx
export const getStateClientState = createFeatureSelector<Client>(
  STATE_CLIENT_FEATURE_KEY
);

export const getStateClientStep = createSelector(
  getStateClientState,
  (state: Client) => state.step
);


export const getStateClientLoaded = createSelector(
  getStateClientState,
  (state: Client) => state.loaded
);

export const getStateClient = createSelector(
  getStateClientState,
  (state: Client) => state
);

export const stateClientSelector = {
  getStateClientStep,
  getStateClientLoaded,
  getStateClient
}

