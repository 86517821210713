export class ResetPasswordMessages {
    reset_messages = {
   
        password: [
            { type: 'required', message: 'Campo requerido.' },
            { type: 'invalid', message: 'Campo incorrecto.' },
            { type: 'minlength', message: 'Mínimo 8 caracteres.' },
        ],
        password_confirmation: [
            { type: 'required', message: 'Campo requerido.' },
            { type: 'confirmar', message: 'Las contraseñas no coinciden.' },
        ],
      
    };

    getResetPasswordMessages() {
        return this.reset_messages;
    }
}