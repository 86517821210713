export class BussinesProfileMessages {
    bussines_messages = {
        commercial_name: [
            { type: 'required', message: 'Campo requerido.' },
            { type: 'minlength', message: 'Mínimo 2 caracteres.'},
            { type: 'maxlength' , message: 'Maximo 255 carácteres.'}
        ],
        cnae: [
            { type: 'required', message: 'Campo requerido.' },
            { type: 'minlength', message: 'Mínimo 2 caracteres.'},
            { type: 'maxlength' , message: 'Maximo 30 carácteres.'}
        ],
        contact_address: [
            { type: 'required', message: 'Campo requerido.' },
            { type: 'minlength', message: 'Mínimo 2 caracteres.'},
            { type: 'maxlength' , message: 'Maximo 255 carácteres.'}
        ],
       
        contact_address_number: [
            { type: 'required', message: 'Campo requerido.' },
            { type: 'min', message: 'Mínimo 2 .'},
            { type: 'maxlength' , message: 'Maximo 50.'}
        ],
        contact_address_floor: [
            { type: 'required', message: 'Campo requerido.' },
            { type: 'minlength', message: 'Mínimo 2 caracteres.'},
            { type: 'maxlength' , message: 'Maximo 20 carácteres.'}
        ],
        contact_address_postal_code: [
            { type: 'required', message: 'Campo requerido.' },
            { type: 'minlength', message: 'Mínimo 2 caracteres.'},
            { type: 'maxlength' , message: 'Maximo 20 carácteres.'}
        ],
        contact_address_locality: [
            { type: 'required', message: 'Campo requerido.' },
            { type: 'minlength', message: 'Mínimo 2 caracteres.'},
            { type: 'maxlength' , message: 'Maximo 50 carácteres.'}
        ],
        contact_address_province: [
            { type: 'required', message: 'Campo requerido.' },
            { type: 'minlength', message: 'Mínimo 2 caracteres.'},
            { type: 'maxlength' , message: 'Maximo 50 carácteres.'}
        ],
        contact_address_phone: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            { type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_20_CHARACTERS'},
            { type: 'invalidPhone', message: 'VALIDATIONS.INVALID_PHONE_IN' }
        ],
        contact_address_mobile_phone: [
            { type: 'required', message: 'Campo requerido.' },
            { type: 'minlength', message: 'Mínimo 2 caracteres.'},
            { type: 'maxlength' , message: 'Maximo 20 carácteres.'},
            { type: 'invalidPhone', message: 'Teléfono invalido en' },
        ],
        contact_address_person_name: [
            { type: 'required', message: 'Campo requerido.' },
            { type: 'minlength', message: 'Mínimo 2 caracteres.'},
            { type: 'maxlength' , message: 'Maximo 30 carácteres.'}
        ],
        contact_address_email:[
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'pattern', message: 'VALIDATIONS.INVALID_FORMAT' },
            { type: 'email', message: 'VALIDATIONS.IT_MUST_BE_IN_THE_FORMAT_ABCD' },
        ],
        social_name: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            { type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_255_CHARACTERS'}
        ],
        identification_document: [
            { type: 'required', message: 'Campo requerido.' },
            { type: 'minlength', message: 'Mínimo 5 caracteres.'},
            { type: 'maxlength' , message: 'Maximo 20 carácteres.'}
        ],
        legal_address: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            { type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_255_CHARACTERS'}
        ],
        legal_address_number: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            { type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_50_CHARACTERS'}
        ],
        legal_address_floor: [
            { type: 'required', message: 'Campo requerido.' },
            { type: 'minlength', message: 'Mínimo 2 caracteres.'},
            { type: 'maxlength' , message: 'Maximo 20 carácteres.'}
        ],
        legal_address_postal_code: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            { type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_20_CHARACTERS'}
        ],
        legal_address_locality: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            { type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_50_CHARACTERS'}
        ],
        legal_address_province: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            { type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_50_CHARACTERS'}
        ],
        legal_address_country_id: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            
        ],
        legal_address_phone: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            { type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_20_CHARACTERS'},
            { type: 'invalidPhone', message: 'VALIDATIONS.INVALID_PHONE_IN' }
        ],
        legal_address_mobile_phone: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            { type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_20_CHARACTERS'},
            { type: 'invalidPhone', message: 'VALIDATIONS.INVALID_PHONE_IN' }
        ],
        legal_address_email:[
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'pattern', message: 'VALIDATIONS.INVALID_FORMAT' },
            { type: 'email', message: 'VALIDATIONS.IT_MUST_BE_IN_THE_FORMAT_ABCD' },
        ],
        user_email: [
            { type: 'required', message: 'Campo requerido.' },
            { type: 'pattern', message: 'Formato invalido.' },
            { type: 'email', message: 'Debe tener el formato abcd@gmail.com.' },
        ],
        password: [
            { type: 'required', message: 'Campo requerido.' },
            { type: 'invalid', message: 'Campo incorrecto.' },
            { type: 'minlength', message: 'Mínimo 8 caracteres.' },
        ],
        password_confirmation: [
            { type: 'required', message: 'Campo requerido.' },
            { type: 'confirmar', message: 'Las contraseñas no coinciden.' },
        ],
        headlineName: [
            { type: 'required', message: 'Campo requerido.' },
            { type: 'minlength', message: 'Mínimo 2 caracteres.'},
            { type: 'maxlength' , message: 'Maximo 20 carácteres.'}
        ],
        cardNumber: [
            { type: 'required', message: 'Campo requerido.' },
            { type: 'max' , message: 'Maximo 16 numeros'}
        ],
        cardExpiry: [
            { type: 'required', message: 'Campo requerido.' },
           
        ],
        cardCvc: [
            { type: 'required', message: 'Campo requerido.' },
           
        ]
    };

    getBussinesProfileMessages() {
        return this.bussines_messages;
    }
}