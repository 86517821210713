export class BankPoolMessages {
    bankPool_messages = {

        concedido: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'pattern', message: 'VALIDATIONS.ONLY_POSITIVE_NUMBERS_OR_A_MAXIMUN_OF' }
        ],
        init_date :[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        end_date:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        interes:[
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'pattern', message: 'VALIDATIONS.ONLY_POSITIVE_NUMBERS_OR_A_MAXIMUN_OF' }
        ],
        type2:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        quote :[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'pattern', message: 'VALIDATIONS.ONLY_POSITIVE_NUMBERS_OR_A_MAXIMUN_OF' }
        ],
        investment_import :[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'pattern', message: 'VALIDATIONS.ONLY_POSITIVE_NUMBERS_OR_A_MAXIMUN_OF' }
        ],
        investment_term :[
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'pattern', message: 'VALIDATIONS.ONLY_POSITIVE_NUMBERS_OR_A_MAXIMUN_OF' }
        ],
        num_participations :[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'pattern', message: 'VALIDATIONS.ONLY_POSITIVE_NUMBERS_OR_A_MAXIMUN_OF' }
        ],
        type:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        period:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        product_type: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'pattern', message: 'VALIDATIONS.ONLY_POSITIVE_NUMBERS_OR_A_MAXIMUN_OF' }
        ],
        
    };

    getBankPoolMessages() {
        return this.bankPool_messages;
    }
}