import { StatClientActions, StateClientActionType } from './state-client.actions';

export const STATE_CLIENT_FEATURE_KEY = 'stateClient';

export interface Client {       
  company_profile_type_id?: string;
  commercial_name?: string;
  cnae?: string;
  contact_address?: string;
  contact_address_number?: string;
  contact_address_floor?: string;
  contact_address_postal_code?: string;
  contact_address_locality?: string;
  contact_address_province?: string;
  contact_address_phone?: string;
  contact_address_mobile_phone?: string;
  contact_address_person_name?: string;
  contact_address_person_name_alternative?: string;
  contact_address_email? : string;
  contact_address_email_alternative?: string;
  contact_address_email_notification? : string;
  company_status_id? : string;
  user_identification_document? : string;
  company_representative_type_id?: string;
  social_name?: string;
  identification_document?: string;
  legal_address?: string;
  legal_address_number?: string;
  legal_address_floor?: string;
  legal_address_postal_code?: string;
  legal_address_locality?: string;
  legal_address_province?: string;
  legal_address_phone?: string;
  legal_address_mobile_phone?: string;
  legal_representative_name?:string;
  is_demo?: boolean,
  start_demo_date?: string;
  end_demo_date?:string;
  rate_id?: number;
  loaded: boolean;
  step: number;
}

export const initialState: Client = {
 loaded: false,
 step: 1
}

export function reducer(
  state: Client = initialState,
  action: StatClientActions,
): Client {
  switch (action.type) {

    case  StateClientActionType.SET_STEP: {
      return {
        ...state,
        step: action.payload.step
      }
    }

    case StateClientActionType.REGISTER_ONE_STEP: {
      return {
        ...action.payload.register,
        step: state.step,
      }
    }

    case StateClientActionType.REGISTER_TWO_STEP: {
      return {
        ...action.payload.register,
        step: state.step,
      }
    }

    case StateClientActionType.SET_INIT_STATE: {
      return {
        ...initialState
      }
    }

    default:
      return state;
  }
}