import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { take } from 'rxjs';
import { LoadingService } from '../../../services/loading.service';
import { BackendService } from '@finergia-core/backend';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientMessages } from '@finergia-core/shared';
import { Router } from '@angular/router';

@Component({
  selector: 'finergia-core-modal-general-import-template',
  templateUrl: './modal-general-import-template.component.html',
  styleUrls: ['./modal-general-import-template.component.scss'],
})
export class ModalGeneralImportTemplateComponent implements OnInit {

  fileData: any = File ;

  importClientForm: any;

  messages: any;

  templates: any = [];

  nameFile: string = '';

  error: any;

  title: any;

  urlSelect: any;

  urlSent: any;

  forName: any;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private loading: LoadingService,
    private backend: BackendService,
    private router: Router
  ) {}

  
  ngOnInit(): void {

    this.fileData = null;

    this.getTemplates();
   
  }

  getTemplates(){

    this.backend.get(this.urlSelect).pipe(take(1)).subscribe(({data})=>{

      this.templates = data;
      
      this.validaciones();

    }, error =>{

      this.error = error.error;

      this.loading.hideLoading();
      
    });

  }

  validaciones(){

    this.importClientForm = new FormGroup({
      

      [this.forName]: new FormControl( '',
        [ 
          Validators.required, 
         
      ]),

      document: new FormControl( '',
        [
          Validators.required, 
          
      ]),

    
     
    })
    console.log(this.importClientForm.value, 'form');
    const messages = new ClientMessages();

    this.messages = messages.getClientMessages();
  }
  closeModal(value: any){

    this.activeModal.close(value);
  }

  /* File */

  changeInput(archivo: File){
    
    this.fileData = archivo;

    this.importClientForm.get('excel_file').setValue(archivo.name);

    this.importClientForm.updateValueAndValidity();

  }

  onFileSelect(event: Event) {

    const files = (event.target as HTMLInputElement).files;

    if ( files && files.length > 0) {

      const file = files[0];
      
      this.nameFile = file.name;
      
      this.fileData = file;
      
    }

  }

  addFile(){

    this.activeModal.close(this.fileData);
    
  }

  opePlantilla(){
    this.activeModal.close();
    this.router.navigateByUrl('excel-templates');
    
  }
  

  submit(){

    this.error = undefined;

    this.loading.showLoading();

    const formData = new FormData();

    formData.append('document', this.fileData);

    formData.append(this.forName, this.importClientForm?.get(this.forName)?.value),
    
    this.backend.post_file(this.urlSent, formData).pipe(take(1)).subscribe(({data})=>{

      this.loading.hideLoading();

      this.close(true);

    }, error =>{

      this.error = error.error;

      this.loading.hideLoading();

    })
  }

  close(value: boolean){
    this.activeModal.close(value);
  }

}
