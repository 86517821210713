import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  STATE_ME_FEATURE_KEY,
  MeState,
} from './state-me.reducer';

// Lookup the 'StateMe' feature state managed by NgRx
export const getStateMeState =
  createFeatureSelector<MeState>(STATE_ME_FEATURE_KEY);

export const getStateMeLoaded = createSelector(
  getStateMeState,
  (state: MeState) => state.loaded
);

export const getStateMeLogged = createSelector(
  getStateMeState,
  (state: MeState) => state.logged
);

export const getStateMeError = createSelector(
  getStateMeState,
  (state: MeState) => state.error
);

export const getStateMe = createSelector(
  getStateMeState,
  (state: MeState) => {

    return {
      username: state.username,
      name: state.name,
      last_name: state.last_name,
      email: state.email,
      company: state.company,
      identification_document: state.identification_document,
      phone: state.phone,
      url_image: state.url_image,
      profile: state.profile,
      menu: state.menu,
      company_switch_list: state.company_switch_list,
      profesional_menu: state.profesional_menu,
      company_preference: state.company_preference,
      demo_left_days: state.demo_left_days,
      company_subscription_status: state.company_subscription_status,
      use_subscription: state.use_subscription,
      use_profiles_admin: state.use_profiles_admin,
      company_parent: state.company_parent
    }
  }
);

export const getStateMenu = createSelector(
  getStateMeState,
  (state: MeState) => state.menu
);

export const stateMeSelector = {
  getStateMeLoaded,
  getStateMeError,
  getStateMe,
  getStateMenu,
  getStateMeLogged
}

