<div class="row mt-3">
    <div class="col-9 col-lg-10 col-sm-10 px-6">
        <label for=""> {{ 'GENERAL.CHANGE_CURRENCY'  | translate }} </label>
    </div>
    <div class="col-3 col-lg-2 col-sm-2 px-6">
        <img src="assets/icon/close.svg" alt="" (click)="close()" class="img-close point">
    </div>
</div>
<div class="row mb-4">

    <ng-container *ngIf="showCurrency">
        <div class="col-12 mt-4 px-6" *ngFor="let item of currencies" (click)="updatePreferences(item)">

            <div class="point" [ngClass]="{'language-selected': item.id === cuerrencySelected, 'language-no-selected': item.id !== cuerrencySelected}">

                <div class="row align-items-center">
                    <div class="col-1">
                        <div class="nav-link icon text-center title-currency m-0">
                            {{ item.symbol }}
                        </div>
                    </div>
                    <div class="col-11">
                        <span class="name-language ms-3"> {{ item.name }} </span>
                    </div>
                </div>



            </div>



        </div>
    </ng-container>
    <ng-container *ngIf="!showCurrency">
        <div *ngIf="showCurrency" class="card-body text-center">
            <i class="fa fa-spinner fa-spin fa-5x" aria-hidden="true"></i>
        </div>
    </ng-container>


</div>