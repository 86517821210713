import { NgbDateParserFormatter, NgbDateStruct, NgbDatepickerI18n } from "@ng-bootstrap/ng-bootstrap";
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationWidth } from "@angular/common";

@Injectable()
export class MomentDateFormatter extends NgbDateParserFormatter {
     DT_FORMAT = 'DD/MM/YYYY';
  
    parse(value: string): NgbDateStruct | null{

        if (value) {
            value = value.trim();
            moment(value, this.DT_FORMAT)
        }
        
        return null;

    }
    
    format(date: NgbDateStruct): string {

        if (!date) return '';
        let mdt = moment([date.year, date.month - 1, date.day]);
        if (!mdt.isValid()) return '';
        return mdt.format(this.DT_FORMAT);
    }
  
}
@Injectable()
export class NgbDateFRParserFormatter1 extends NgbDateParserFormatter {
    DT_FORMAT = 'MMM YYYY';
 
   parse(value: string): NgbDateStruct | null{

     
       if (value) {
           value = value.trim();
           moment(value, this.DT_FORMAT)
       }
       
       return null;

   }
   
   format(date: NgbDateStruct): string {
    
       if (!date) return '';
       let mdt = moment([date.year, date.month - 1, date.day]);
       if (!mdt.isValid()) return '';
       return mdt.format(this.DT_FORMAT);
   }
 
}

const I18N_VALUES: any = {
    en: {
      weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    es: {
      weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá', 'Do'],
      months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    }
};

@Injectable()
export class Language {
  language = 'es';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

    constructor(
        private _i18n: Language, 
        private translateService: TranslateService) {
        super();
        this._i18n.language =  this.translateService.currentLang;
    }

    getWeekdayLabel(weekday: number, width?: TranslationWidth | undefined): string {
        return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
    }

    getWeekdayShortName(weekday: number): string {
        return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
    }
    getMonthShortName(month: number): string {
        return I18N_VALUES[this._i18n.language].months[month - 1];
    }
    getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }
    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }

}

export function dateToObject(date: string = new Date().toISOString()): NgbDateStruct {

        const dateSelected = moment(date);
        return {
            year: +dateSelected.format('YYYY'),
            month: +dateSelected.format('MM'),
            day: +dateSelected.format('DD')
        };
         
}

export function objectToString(date: NgbDateStruct): string {
    if(date){
        let data = `${date.year}-${date.month.toString().padStart(2,'0')}-${date.day.toString().padStart(2,'0')}`;

        return moment( data ).format('YYYY-MM-DD');
    } else {
        return '';
    }
}

export function objectToStringg(date: NgbDateStruct): string {
    if(date){
        let data = `${date.year}-${date.month.toString().padStart(2,'0')}-${date.day.toString().padStart(2,'0')}`;

        return moment( data ).format('MMM YYYY');
    } else {
        return '';
    }
}

export function getToday(): string {
    return moment().format('YYYY-MM-DD');
}

export function getDateMoment( date: string ): string {
    return moment( date ).format('DD/MM/YYYY');
}

export function getDateMomentHours( date: string ): string {
    return moment( date ).format('DD-MM-YYYY kk:mm:ss');
}

export function getHour( date: string ): string {
    return moment( date ).format('HH:mm');
}


export function getYesterdaySubtract(): string {
    return moment().subtract(2,'days').format('YYYY-MM-DD');
}
export function getTodaysubtract(): string {
    return moment().subtract(30,'days').format('YYYY-MM-DD');
}

export function getYearSubTract(): string {
    return moment().subtract(20, 'years').format('YYYY-MM-DD');
}

export function getYearAdd(): string {
    return moment().add(20, 'years').format('YYYY-MM-DD');
}
