/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { ChangeDetectorRef, Component, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, NavigationStart, Router } from '@angular/router';
import { stateMeFacade } from '@finergia-core/state-me';
import { TranslateService } from '@ngx-translate/core';
import { WebSocketService } from 'libs/shared/src/lib/services/websocket';
import { take, filter } from 'rxjs';
declare function init_plugins(): any;
declare let bootstrap: any;
declare let Tawk_API: any;

declare let notif: any;
declare let $: any;

@Component({
  selector: 'finergia-core-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css'],
  providers:  [ WebSocketService ]
})
export class PagesComponent implements OnInit {

  showMenu2 = false;
  title_toast = '';
  showSuscription = false;
  daysDemo = 0;

  notification: any;
  home = false;
  menu2: string[] = [
    'control-box',
    'profile',
    'clients',
    'profesional-settings-client-status',
    'profesional-settings-document-templates',
    'profesional-settings-email-templates',
    'profesional-settings-services',
    'profesional-settings-users',
    'profesional-emails',
    'professional-settings-form',
    'profesional-settings-financial-tables',
    'profesional-settings-general-accounting-plan'
  ];

  demoClasses: any;

  constructor(private facade: stateMeFacade,
    private router: Router,
    private translate: TranslateService,
    private changeDetector: ChangeDetectorRef,
    private websocket: WebSocketService,
    private _activatedRoute: ActivatedRoute,
    ) {
    this.router.events
    .pipe(filter((event: any) => event instanceof NavigationEnd))
    .subscribe((event:NavigationEnd) => {

      if( ['profile'].includes(event.url.split('/')[1].replace('/', '') )){
        return;
      } else {
        if(this.menu2.includes(event.url.split('/')[1].replace('/', ''))){
          this.showMenu2 = true;
        } else {
          this.showMenu2 = false;
        }
      }


      this.home  = event.url.split('/')[1].replace('/', '') === 'home' ? true : false;

      //let home = ['home'].includes(event.url.split('/')[1].replace('/', '') );
      //console.log(home);

    });
  }

  viewNotification(type: string, id: number){
    const toast = new bootstrap.Toast($('#liveToast'));
    toast.hide();
    if(type == 'budget'){

      this.router.navigate(['budget-control/' + id]);

    } else if (type == 'upload_book') {

      this.router.navigate(['document-management/importations']);

    } else if (type == 'treasury') {

      this.router.navigate(['treasury/treasury-control/' + id]);

    } else if(type == 'upload_client'){

      this.router.navigate(['settings-clients']);

    } else if(type == 'upload_provider'){

      this.router.navigate(['settings-providers']);

    }else if(type == 'upload_collection'){

      let data: any ={

        change:'upload_collection'

      }

      const navigationExtras: NavigationExtras = {
        state: {
          data: data,
        }
      };


      this.router.navigate(['document-management/importations'], navigationExtras);

    }else if(type == 'upload_payment'){

      let data: any ={

        change:'upload_payment'

      };

      const navigationExtras: NavigationExtras = {

        state: {

          data: data,
        }

      };


      this.router.navigate(['document-management/importations'], navigationExtras);

    }else if (type == 'imported_collection_payment'){

      this.router.navigate(['treasury/treasury-control/' + id]);
    } else {

      this.router.navigate(['financial-analysis/' + id]);
    }
  }

  showNotification(notification: any, type: string = 'success'){

    this.notification = notification;

    if(notification.data.type == 'budget'){

      this.title_toast = 'BUDGET_CONTROL.NAME';

    } else if (notification.data.type == 'upload_book'){

      this.title_toast = 'CONTROL_PANEL.IMPORT_JOURNAL_LEDGER';

    } else if (notification.data.type == 'treasury'){

      this.title_toast = 'TREASURY_CONTROL.NAME';

    } else if(notification.data.type == 'upload_client' || notification.data.type == 'upload_provider'){

      this.title_toast = 'CONTROL_PANEL.IMPORT_CUSTOMERS_SUPPLIERS';

    } else if (notification.data.type == 'upload_collection' || notification.data.type == 'upload_payment'){

      this.title_toast = 'CONTROL_PANEL.IMPORT_RECEIPTS_PAYMENTS';

    } else if (notification.data.type == 'imported_collection_payment'){

      this.title_toast = 'CONTROL_PANEL.RECEIPTS_AND_PAYMENTS';

    } else if (notification.data.type == 'integrator_sync') {

      this.title_toast = 'CONNECTIONS.NAME';

    } else if(notification.data.type == 'company'){

      this.title_toast = 'GENERAL.CLIENTS_SUPPLIERS';

    } else {

      this.title_toast = 'FINANCIAL_ANALYSIS.FINANCIAL_ANALYSIS';

    }

    const toast = new bootstrap.Toast($('#liveToast'));
    toast.show()
    /* console.log(notification);
    $("#ui_notifIt").remove();
    notif({
        type: type,
        msg: `<span>
                  ${notification.message}.
                <a style="text-decoration: underline;" class="view-${notification.data.id}"> Ver </a>
              </span> `,
        position: "right",
        width: 500,
        height: 60,
        autohide: false,
    }); */

    this.websocket.update({});
}

  ngOnInit(): void {

    /* this.facade.loadOfLocalStorage(); */
    this.facade.loadMe();
    this.facade.loaded$.pipe(take(2)).subscribe(async (loaded)=>{
      if(loaded){

        const me = await this.facade.me$.pipe(take(1)).toPromise();

        if(me && me.demo_left_days && me.demo_left_days  < 15 && me.company_subscription_status === 'demo' && me.use_subscription){

          this.showSuscription = true;

          this.daysDemo = me.demo_left_days;

          this.demoClasses = document.querySelector('.barra-fija');

          /* con estoo ve si tiene la clase barra fija y agrega la nueva clase de margin */

          if ( this.demoClasses) {

            this.demoClasses.classList.add('margin-demo');

          }

        }

        Tawk_API.visitor = {
          name : me?.name + ' ' + me?.last_name,
          email : me?.email
        };



        this.websocket.connect();
        this.websocket.data$.subscribe((data: any)=>{
          if(data && data.notification){
            this.showNotification(data.notification);
          }
        });
      }
    });
  //  init_plugins();


    this.facade.me$.pipe(take(2)).subscribe((data: any)=>{

      if(data && data.company_preference && data.company_preference.language){
        this.translate.setDefaultLang(data.company_preference.language.abbreviation.toLowerCase());
        this.translate.use(data.company_preference.language.abbreviation.toLowerCase());
      }

      if(data && data.menu && data.menu.length  > 0){
        if(this.router.url === '/') {
          this.router.navigateByUrl(data.menu[0].route ? data.menu[0].route : '');

          this.changeDetector.detectChanges();
        }
      }
    })
  }

   hovermenu() {

    $(".app-sidebar").hover(function () {
        if ($('.app').hasClass('sidenav-toggled')) {
            $('.app').addClass('sidenav-toggled-open');
        }
    }, function () {
        if ($('.app').hasClass('sidenav-toggled')) {
            $('.app').removeClass('sidenav-toggled-open');
        }
    });

    /* aqui hace cuando cambien de pagina en el menu el valida que si en alguna hija este la clase y la agrega */
    if (this.showSuscription) {

      this.demoClasses = document.querySelector('.barra-fija');

      if ( this.demoClasses) {

        this.demoClasses.classList.add('margin-demo');

      }


    }
  }
  redirectSuscribe(){
    this.router.navigateByUrl('business-profile');
  }
  hiddenALert(){
    this.showSuscription =!this.showSuscription;
    if (this.demoClasses) {
      this.demoClasses.classList.remove('margin-demo');
    }

  }

}
