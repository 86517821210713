import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'symbolCurrency'
})
export class SymbolCurrencytPipe implements PipeTransform {

  transform(value: string, showCurrency: boolean = false): string {
    
    let company_prefence: string | null = localStorage.getItem('company_preference');

    if(company_prefence != null){

      const preferences = JSON.parse(company_prefence);

      const symbol = preferences.currency.symbol;

      return value + ( showCurrency ? ' ' + symbol : '');

    } else {

      return value;

    }
    
  }

}
