export class RegisterMessages {
    register_messages = {
        commercial_name: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_255_CHARACTER'}
        ],
        companyName: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_255_CHARACTER'}
        ],
        company_phone: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_20_CHARACTERS'},
            {type: 'invalidPhone', message: 'VALIDATIONS.INVALID_PHONE_IN' },
        ],
        howMany: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'min', message: 'VALIDATIONS.MINIMUN_2'},
            {type: 'max' , message: 'VALIDATIONS.MAXIMUN_255'},
            {type: 'pattern' , message: 'VALIDATIONS.ONLY_WHOLE_NUMBERS_ARE_ALLOWED'},
        ],
        quantity_personal: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'min', message: 'VALIDATIONS.MINIMUN_1'},
            {type: 'max' , message: 'VALIDATIONS.MAXIMUN_255'},
            {type: 'pattern' , message: 'VALIDATIONS.ONLY_WHOLE_NUMBERS_ARE_ALLOWED'},
        ],
        description_use: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_255_CHARACTER'}
        ],
        cnae: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_30_CHARACTERS'}
        ],
        contact_address: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_255_CHARACTER'}
        ],
       
        contact_address_number: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'min', message: 'VALIDATIONS.MINIMUN_2'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_50'}
        ],
        contact_address_floor: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_20_CHARACTERS'}
        ],
        contact_address_postal_code: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_20_CHARACTERS'}
        ],
        contact_address_locality: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_50_CHARACTERS'}
        ],
        contact_address_province: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_50_CHARACTERS'}
        ],
        contact_address_email: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'pattern', message: 'VALIDATIONS.INVALID_FORMAT' },
            {type: 'email', message: 'VALIDATIONS.IT_MUST_BE_IN_THE_FORMAT_ABCD' },
        ],
        contact_address_phone: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_20_CHARACTERS'},
            {type: 'invalidPhone', message: 'VALIDATIONS.INVALID_PHONE_IN' },
        ],
        contact_address_mobile_phone: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_20_CHARACTERS'},
            {type: 'invalidPhone', message: 'VALIDATIONS.INVALID_PHONE_IN' },
        ],
        contact_address_person_name: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_30_CHARACTERS'}
        ],
        social_name: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_255_CHARACTER'}
        ],
        identification_document: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'minlength', message: 'Mínimo 5 caracteres.'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_20_CHARACTERS'},
            {type: 'invalidCompanyId', message: 'VALIDATIONS.CIF_NIE_NIF_INCORRECT' }
        ],
        legal_address: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_255_CHARACTER'}
        ],
        legal_address_number: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_50_CHARACTERS'}
        ],
        legal_address_floor: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_20_CHARACTERS'}
        ],
        legal_address_postal_code: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_20_CHARACTERS'}
        ],
        legal_address_locality: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_50_CHARACTERS'}
        ],
        legal_address_province: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_50_CHARACTERS'}
        ],
        legal_address_phone: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_20_CHARACTERS'},
            {type: 'invalidPhone', message: 'VALIDATIONS.INVALID_PHONE_IN' },
        ],
        legal_address_mobile_phone: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_20_CHARACTERS'},
            {type: 'invalidPhone', message: 'VALIDATIONS.INVALID_PHONE_IN' },
        ],
        legal_representative_name: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_255_CHARACTER'}
        ],
        user_email: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'pattern', message: 'VALIDATIONS.INVALID_FORMAT' },
            {type: 'email', message: 'VALIDATIONS.IT_MUST_BE_IN_THE_FORMAT_ABCD' },
        ],
        password: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'invalid', message: 'Campo incorrecto.' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_8_CHARACTERS' },
        ],
        password_confirmation: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'confirmar', message: 'VALIDATIONS.PASSWORDS_DO_NOT_MATCH' },
        ],
        headlineName: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_20_CHARACTERS'}
        ],
        cardNumber: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'max' , message: 'VALIDATIONS.MAXIMUN_16_NUMBERS'}
        ],
        cardExpiry: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        cardCvc: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },  
        ],
        start_demo_date: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },  
        ],
        end_demo_date: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },  
        ],
        company_profile_type_id: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        company_status_id: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        company_representative_type_id: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        user_identification_document: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'minlength', message: 'Mínimo 5 caracteres.'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_20_CHARACTERS'},
            {type: 'invalidCompanyId', message: 'VALIDATIONS.CIF_NIE_NIF_INCORRECT' }
        ],
        email: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'pattern', message: 'VALIDATIONS.INVALID_FORMAT' },
            {type: 'email', message: 'VALIDATIONS.IT_MUST_BE_IN_THE_FORMAT_ABCD' },
        ],
        company_activity_id:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        dont_accept_offers:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ]
    };

    getRegisterMessages() {
        return this.register_messages;
    }
}
