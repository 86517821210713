export class DigitalizacionControlMessages {
    digitalizacionControlMessages = {
        sendTo: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        generateInvoice: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        frequency: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        hour:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        minutes:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        stateId:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        user:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            { type: 'maxlength', message: 'VALIDATIONS.MAXIMUN_200_CHARACTERS'}
        ],
        affair:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            { type: 'maxlength', message: 'VALIDATIONS.MAXIMUN_200_CHARACTERS'}
        ],
        start_date:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        end_Date:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        Observations:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'maxlength', message: 'VALIDATIONS.MAXIMUN_255_CHARACTERS'}
        ],
        expense_type_id:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        km:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        amount_Km:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        date:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        payment_Type_id:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        amount:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],

        email: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'pattern', message: 'VALIDATIONS.INVALID_FORMAT'},
            {type: 'email', message: 'VALIDATIONS.IT_MUST_BE_IN_THE_FORMAT_ABCD'},
        ],
        generate_invoice: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        document_type_id:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        state_id:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        invoice_number:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        cif: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_5_CHARACTERS'},
            {type: 'maxlength', message: 'VALIDATIONS.MAXIMUN_20_CHARACTERS'},
            {type: 'invalidCompanyId', message: 'VALIDATIONS.CIF_NIE_NIF_INCORRECT'}
        ],
        provider:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        invoice_date:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        expiration_date:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        retention_type:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        retention:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        supplied:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        total_bill:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        accounting_date:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        posting_date:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        note:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        supplier_customer_account:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        filename:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
    };

    getDigitalizacionControlMessages() {
        return this.digitalizacionControlMessages;
    }
}
