export class BankMessages {
    bank_messages = {

        bank_id: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        main_contact: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_200_CHARACTERS'}
        ],
        phone: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_20_CHARACTERS'},
            {type: 'invalidPhone', message: 'VALIDATIONS.INVALID_PHONE_IN' },
        ],
        email: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'pattern', message: 'VALIDATIONS.INVALID_FORMAT' },
            {type: 'email', message: 'VALIDATIONS.IT_MUST_BE_IN_THE_FORMAT_ABCD' },
        ],
        address: [
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_255_CHARACTERS'}
        ],
        postal_code: [
            { type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_10_CHARACTERS'}
        ],
        locality: [
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_255_CHARACTERS'}
        ],
        iban: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_4_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_255_CHARACTERS'}
        ],
        swift: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_255_CHARACTERS'}
        ],
        iban_1: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_4_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_4_CHARACTERS'}
        ],
        iban_2: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_4_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_4_CHARACTERS'}
        ],
        iban_3: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_4_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_4_CHARACTERS'}
        ],
        iban_4: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_4_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_4_CHARACTERS'}
        ],
        iban_5: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_4_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_4_CHARACTERS'}
        ],
        iban_6: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_4_CHARACTERS'},
            {type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_4_CHARACTERS'}
        ],
    };

    getBankMessages() {
        return this.bank_messages;
    }
}