<div class="modal-modal-delete-template m-5">

    <div class="modal-header p-3 ps-5">

        <h3 class="modal-title ps-1"> {{ title }} </h3>

    </div>

    <div class="modal-body">

        <div class="col-lg-12 col-md-12 mt-2 p-2">
            <p class="text-p m-0">{{ text1 }}</p>
            <p class="text-p mt-1"> {{ text2 }}?</p>

        </div>

        <div class="col-12 text-center mt-6 pb-5">
            <div class="btn-list">
                <button type="button" class="btn btn-outline-secondary  point" (click)="closeModal(false)">
                    {{ 'GENERAL.CANCEL' | translate }}
                </button>
                <button type="button" class="btn btn-secondary  point" (click)="closeModal(true)">
                    {{ 'GENERAL.YES_DELETE' | translate }}
                </button>
            </div>

        </div>


    </div>

</div>