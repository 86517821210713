<div class="row mt-3">
    <div class="col-9 col-lg-10 col-sm-10 px-6">
        <label for=""> {{ 'GENERAL.CHANGE_LANGUAGE'  | translate }} </label>
    </div>
    <div class="col-3 col-lg-2 col-sm-2 px-6">
        <img src="assets/icon/close.svg" alt="" (click)="close()" class="img-close">
    </div>
</div>
<div class="row mb-4">
    <div class="col-12 mt-4 px-6" *ngFor="let item of languages" (click)="changeLanguage(item)">
        <div [ngClass]="{'language-selected': item.abbreviation === languageSelected, 'language-no-selected': item.abbreviation !== languageSelected}">
            <img *ngIf="item.id == 1" src="assets/icon/es.svg" [alt]="item.abbreviation">
            <img *ngIf="item.id == 2" src="assets/icon/en.svg" [alt]="item.abbreviation">
            <span class="name-language ms-3"> {{ item.name }} </span>
        </div>
        
    </div>
</div>