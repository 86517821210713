import { Action } from '@ngrx/store';
import { Client } from './state-client.reducer';


export enum StateClientActionType {
  SET_STEP = '[StateClient] SET_STEP',
  REGISTER_ONE_STEP = '[StateClient] REGISTER_ONE_STEP',
  REGISTER_TWO_STEP = '[StateClient] REGISTER_TWO_STEP',
  SET_INIT_STATE = '[StateClient] SET_INIT_STATE'
}

export class SetStepAction implements Action {
  readonly type = StateClientActionType.SET_STEP;
  constructor(public payload: { step: number }) {}
}

export class setStepOneAction implements Action {
  readonly type = StateClientActionType.REGISTER_ONE_STEP;
  constructor(public payload: { register: Client }) {}
}

export class setStepTwoAction implements Action {
  readonly type = StateClientActionType.REGISTER_TWO_STEP;
  constructor(public payload: { register: Client }) {}
}

export class setInitalStateAction implements Action {
  readonly type = StateClientActionType.SET_INIT_STATE;
}

export type StatClientActions = SetStepAction | setStepOneAction | setStepTwoAction | setInitalStateAction;