import { Injectable } from '@angular/core';
import { BackendService } from '@finergia-core/backend';
import { of, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class StateMeService {
    login(login: any) {
        return this.backendService.post_client('oauth/token_admin', login);
    }

    loadMe() {
        return this.backendService.get('me');
    }

    updateMe(data: any) {
        return this.backendService.post('me', data);
    }

    updateImage(data: any){
        return this.backendService.post('update_me_image', data);
    }

    isLogged() {
        var logueado: boolean;
        if (localStorage.getItem('token') && localStorage.getItem('refresh_token')) {
            return true;
        } else {
            logueado = false;
        }
        return logueado;
    }

    guardarStorage(data: any) {
        console.log(data);
        localStorage.setItem('token', data.access_token);
        localStorage.setItem('expires_in', data.expires_in);
        localStorage.setItem('name', data.name);
        localStorage.setItem('refresh_token', data.refresh_token);
        localStorage.setItem('username', data.username);
        localStorage.setItem('last_name', data.last_name);
    }

    logout(){
        localStorage.removeItem('token');
        localStorage.removeItem('expires_in');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('username');
        localStorage.removeItem('name');
        localStorage.removeItem('last_name');
    }


    constructor(private backendService: BackendService) {}
}
