export class ClientMessages {
    clientsMessages = {
        commercial_name: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS' },
            { type: 'maxlength', message: 'VALIDATIONS.MAXIMUN_50_CHARACTERS' }
        ],
        code: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'minlength', message: 'VALIDATIONS.MINIMUN_1_CHARACTERS' },
            { type: 'maxlength', message: 'VALIDATIONS.MAXIMUN_50_CHARACTERS' }
        ],
        cif: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'minlength', message: 'VALIDATIONS.MINIMUN_5_CHARACTERS'},
            { type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_20_CHARACTERS'},
            { type: 'invalidCompanyId', message: 'VALIDATIONS.WRONG_CIF_NIE_OR_NIF' }
        ],
        identification_document: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'minlength', message: 'VALIDATIONS.MINIMUN_5_CHARACTERS'},
            { type: 'maxlength', message: 'VALIDATIONS.MAXIMUN_20_CHARACTERS' },
            { type: 'invalidCompanyId', message: 'VALIDATIONS.WRONG_CIF_NIE_OR_NIF' }
        ],
        contable_account_description: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS' },
            { type: 'maxlength', message: 'VALIDATIONS.MAXIMUN_50_CHARACTERS' }
        ],
        contable_account: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        company_payment_method_id: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        template_excel_provider_company_id:[
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        excel_import: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        excel_file: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        ledger_account:[
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        associated_Income:[
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        template_excel_client_company_id:[
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ],
        forName:[
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' }, 
        ]
    };

    getClientMessages() {
        return this.clientsMessages;
    }
}
