import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { StateMeService } from '../state-me.service';
import { BackendService } from '@finergia-core/backend';
import { stateMeFacade } from '../+state/state-me.facade';
import { take } from 'rxjs';
// Servicsa


// Config


@Injectable()
export class VerificaTokenGuard implements CanActivate {

  log: boolean = false;

  // url: string = URL_SERVICIOS;

   timeRefresh: number = 1000;

  constructor(
    public _backend: BackendService,
    private service: StateMeService,
    private facade: stateMeFacade,
    private Router: Router
  ) {

    this.log = this.service.isLogged()

  }

  canActivate(): Promise<boolean> | boolean {

    console.log(this.service.isLogged() );

    if ( this.service.isLogged() ) {
      this.facade.loadMe();
      return new Promise((resolve, reject) => {

        this.facade.loaded$.pipe(take(2)).subscribe((loaded) => {
            if (loaded) {
                this.facade.me$.subscribe((me) => {
                  if(me && me.demo_left_days && me?.demo_left_days <= 0 ) {
                      this.Router.navigateByUrl('/test-time');
                      resolve(false);
                    } else {
                        resolve(true);
                    }
                });
            }
        });
    });

    } else {



      this.facade.logout();
      this.Router.navigateByUrl('/login');
      return false;

    }

  }

  expirado( fechaExp: number ) {

    let ahora = new Date().getTime() / 1000;

    if ( fechaExp < ahora ) {

      return true;

    } else {

      return false;

    }

  }

}
