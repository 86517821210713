export class cardMessages {
    card_messages = {

        card_1: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'pattern', message: 'VALIDATIONS.ONLY_NUMBERS' },
        ],
        
        card_2: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'pattern', message: 'VALIDATIONS.ONLY_NUMBERS' },
        ],

        card_3: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'pattern', message: 'VALIDATIONS.ONLY_NUMBERS' },
        ],

        card_4: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'pattern', message: 'VALIDATIONS.ONLY_NUMBERS' },
        ],

        user_responsable: [
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
            { type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_255_CHARACTERS'}
        ],
        name_card:[
            { type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            { type: 'maxlength' , message: 'VALIDATIONS.MAXIMUN_255_CHARACTERS'}
        ],
        bank_company_product_id: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        
        company_card_type_id: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        company_card_modality_id: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        modality: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        period_start_day: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
        ],
        period_end_day: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
        ],
        payment_day: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
            {type: 'minlength', message: 'VALIDATIONS.MINIMUN_2_CHARACTERS'},
        ],
       
        
    };

    getCardMessages() {
        return this.card_messages;
    }
}