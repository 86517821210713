import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BackendService } from '@finergia-core/backend';
import { LoadingService } from '@finergia-core/shared';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs';

@Component({
  selector: 'finergia-core-modal-export-excel-daily',
  templateUrl: './modal-export-excel-daily.component.html',
  styleUrls: ['./modal-export-excel-daily.component.css'],
})
export class ModalExportExcelDailyComponent implements OnInit {
  
  form: FormGroup | undefined;
  templates: any = [];
  error: any;

  nameFile: string = '';

  fileData: any = null;
  
  constructor(private activeModal: NgbActiveModal,
    private router: Router,
    private loading: LoadingService,
    private backend: BackendService) {}


  ngOnInit(): void {
    this.getTemplates();
  }

  onFileSelect(event: Event) {

    const files = (event.target as HTMLInputElement).files;

    if ( files && files.length > 0) {

      const file = files[0];

      this.nameFile = file.name;
      
      this.fileData = file;
      
      //this.form?.get('document')?.setValue(file);

    }

  }

  getTemplates(){
    this.backend.get('template/template_excel_contable_active_list').pipe(take(1)).subscribe(({data})=>{
      this.templates = data;
      this.validation();
    });
  }

  close(value: boolean){
    this.activeModal.close(value);
  }

  goExcelTemplates(){
    this.activeModal.close();
    this.router.navigateByUrl('excel-templates');
  }

  validation(){
    this.form = new FormGroup({
      template_excel_contable_company_id: new FormControl(0, [Validators.required, Validators.min(1)]),
      document: new FormControl('', [Validators.required]),
      year: new FormControl('', [Validators.required])
    })
  }

  submit(){
    this.error = undefined;
    this.loading.showLoading();
    const formData = new FormData();
    formData.append('document', this.fileData);
    formData.append('template_excel_contable_company_id', this.form?.get('template_excel_contable_company_id')?.value),
    formData.append('year', this.form?.get('year')?.value)
    this.backend.post_file('upload/upload_book', formData).pipe(take(1)).subscribe(({data})=>{
      this.loading.hideLoading();
      this.close(true);
    }, error =>{
      this.error = error.error;
      this.loading.hideLoading();
    })
  }

}
