<div class="sticky">
    <div class="app-sidebar__overlay" data-bs-toggle="sidebar"></div>
    <div class="app-sidebar" [ngClass]="{'mt-7': showSuscription }">
        <div class="side-header" [ngClass]="{'mt-7': showSuscription }">
            <a class="header-brand1" routerLink="home">
                <!-- <img src="assets/images/brand/brand-large.svg" class="header-brand-img light-logo1" alt="logo">
                <img src="assets/images/brand/brand-short.svg" class="header-brand-img desktop-logo" alt="logo"> -->
                <img src="./assets/images/brand/brand-short.svg" class="header-brand-img desktop-logo" alt="logo">
                <img src="./assets/images/brand/brand-short.svg" class="header-brand-img toggle-logo" alt="logo">
                <img src="./assets/images/brand/brand-short.svg" class="header-brand-img light-logo" alt="logo">
                <img src="./assets/images/brand/brand-large.svg" class="header-brand-img light-logo1" alt="logo">
            </a>
            <!-- LOGO -->
        </div>
        <div class="main-sidemenu">
            <div class="slide-left disabled" id="slide-left"><svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
                </svg>
            </div>
            <ul class="side-menu">
                <ng-container *ngFor="let menu of (menu$ | async)">
                    <ng-container *ngIf="menu && !menu.childrens">
                        <li class="slide" [ngClass]="{'slide-border-bottom': menu.id == 37}">
                            <ng-container *ngIf="menu.id != 17">

                                <ng-container *ngIf="menu.external_link">
                                    <a class="side-menu__item" data-bs-toggle="slide" href="https://plataforma.enzimum.es" target="_blank">
                                        <img [src]="'assets/images/menu/'+ menu.icon + '.svg'" class="side-menu__icon ">
                                        <span class="side-menu__label">{{ menu.name }}</span></a>
                                </ng-container>
                                <ng-container *ngIf="!menu.external_link">
                                    <a class="side-menu__item" data-bs-toggle="slide" #rla="routerLinkActive" routerLinkActive="active" [routerLink]="menu.route">
                                        <img [src]=" rla.isActive ?  'assets/images/menu/'+ menu.icon +'-active' + '.svg' 
                                        : 'assets/images/menu/'+ menu.icon + '.svg'" class="side-menu__icon ">
                                        <span class="side-menu__label">{{ menu.name }}</span></a>
                                </ng-container>






                            </ng-container>

                            <ng-container *ngIf="menu.id == 17">
                                <a class="side-menu__item" data-bs-toggle="slide" #rla="routerLinkActive" routerLinkActive="active" href="https://academy.finergia.es/" target="_blank">
                                    <img [src]=" rla.isActive ?  'assets/images/menu/'+ menu.icon +'-active' + '.svg' 
                                    : 'assets/images/menu/'+ menu.icon + '.svg'" class="side-menu__icon ">
                                    <span class="side-menu__label">{{ menu.name }}</span></a>
                            </ng-container>
                        </li>
                    </ng-container>
                    <ng-container *ngIf="menu && menu.childrens">
                        <li class="slide">
                            <a class="side-menu__item" data-bs-toggle="slide" href="javascript:void(0)" #rla="routerLinkActive" routerLinkActive="active">
                                <img [src]="'assets/images/menu/'+ menu.icon + '.svg'" class="side-menu__icon ">
                                <span class="side-menu__label"> {{ menu.name }} </span>
                                <i class="angle fe fe-chevron-right"></i></a>
                            <ul class="slide-menu">
                                <li class="side-menu-label1">
                                    <a href="javascript:void(0)">{{ menu.name }}</a>
                                </li>
                                <li>
                                    <ng-container *ngFor="let item of menu.childrens">
                                        <ng-container *ngIf="item.id !== 38">
                                            <a [routerLink]="item.route" class="slide-item"> 
                                                {{ item.name }}
                                            </a>
                                        </ng-container>

                                        <ng-container *ngIf="item.id === 38">

                                            <a [routerLink]="item.route + '/1'" class="slide-item"> 
                                                {{ item.name }}
                                            </a>
                                        </ng-container>

                                    </ng-container>
                                </li>
                            </ul>
                        </li>
                    </ng-container>
                </ng-container>



            </ul>
            <div class="slide-right" id="slide-right"><svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
                </svg></div>
        </div>
    </div>
    <!--/APP-SIDEBAR-->
</div>