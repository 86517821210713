export interface Digitalization {

    id: number,
    filename: string,
    document_type: string,
    status: string,
    bill_type_id: number,
    invoice_number: string,
    cif: string,
    provider: string,
    invoice_date: string,
    expiration_date: string,
    retention_type: string,
    retention: string,
    supplied: string,
    total_bill: string,
    accounting_date: string,
    posting_date: string,
    seat: string,
    supplier_customer_account: string,
    associated_income_expense: number
}

export class Digitalization  implements Digitalization  {
	constructor(){
        this.id = 0,
        this.filename = '',
        this.document_type = '',
        this.status =  '',
        this.bill_type_id = 0,
        this.invoice_number= '',
        this.cif = '',
        this.provider = '',
        this.invoice_date = '',
        this.expiration_date = '',
        this.retention_type = '',
        this.retention = '',
        this.supplied = '',
        this.total_bill = '',
        this.accounting_date = '',
        this.posting_date = '',
        this.seat = '',
        this.supplier_customer_account = '',
        this.associated_income_expense = 0
	}
}
