export * from './lib/backend.module';
export * from './lib/backend.service';

//interfaz

export * from './lib/types/register.type';
// interfaz

export * from './lib/types/business-profile.type';
export * from './lib/types/settings-document-form.type';
export * from './lib/types/users-clients.type';
export * from './lib/types/clients.type';
export * from './lib/types/forms.type';
export * from './lib/types/digitalization.type';