import { Injectable, OnDestroy } from '@angular/core';
import { WebSocketSubject } from 'rxjs/webSocket';
import Pusher, { Channel } from 'pusher-js';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '@finergia-core/env/environment';
import { BackendService } from '@finergia-core/backend';
import { stateMeFacade } from '@finergia-core/state-me';

@Injectable({
  providedIn: 'root',
})


export class WebSocketService implements OnDestroy {
  private socket$: WebSocketSubject<{}> | undefined;
  private pusher: Pusher | undefined;
  private channel: Channel | undefined;
  private sync: BehaviorSubject<object> = new BehaviorSubject({});
  public data$ = this.sync.asObservable();
  constructor(
    private backend: BackendService,
    private facade: stateMeFacade
  ) {

  }

  connect() {
    this.facade.loaded$.pipe(take(2)).subscribe(async (loaded) => {
      if (loaded) {
        this.pusher = new Pusher('key_JdwDDV9sWV7kEhXgFGbEKtfjrkPdFe2c', {
          cluster: environment.websocket.cluster,
          wsHost: environment.websocket.wsHost,
          wssPort: environment.websocket.wssPort,
          wsPort: environment.websocket.wsPort,
          enableStats: environment.websocket.enableStats,
          disableStats: true,
          forceTLS: environment.websocket.forceTLS,
          enabledTransports: ['ws', 'wss'],
          activityTimeout: 30000, // 30 seconds
          authEndpoint: environment.websocket.authEndpoint,
          auth: {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${this.backend.getTokenLocalStorage()}`
            }
          }
        });
        const me = await this.facade.me$.pipe(take(1)).subscribe((me)=>{
          this.channel = this.pusher?.subscribe('private-notification-channel.' + me?.company?.id);
          // listen event
          this.channel?.bind('notification-channel', ((data: any) => { // event name
            if (data) {
              
              this.update(data);
            }

          }));
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          this.channel?.bind('pusher:subscription_succeeded', function () {
          });

          this.pusher?.connect();
        });

      }
    });

  }

  update(value: any){
    this.sync.next(value);
  }

  desconnect() {
    try {
      this.pusher?.disconnect();
    } catch (e) {
      console.log(e);
    }
  }



  ngOnDestroy() {
    this.socket$?.complete();
  }
}
