<div class="modal-import-clients m-5">

    <div class="modal-header">
        <h3 class="modal-title"> {{'DOCUMENT_MANAGEMENT.MATTER' | translate }} {{ title }} </h3>

        <img src="../assets/icon/close.svg" class="header-brand-img desktop-logo point" alt="search" (click)="closeModal(false)">

    </div>

    <div class="modal-body">

        <div class="col-12 p-xl-0">

            <div class="alert alert-primary pb-5" role="alert">

                <img class="me-2" src="assets/icon/attention.svg">

                <span class="alert-title alert-title"> {{ 'SETTINGS_CLIENTS.YOU_SHOULD' | translate }} 

                    <b class="point" (click)="opePlantilla()"> {{ 'SETTINGS_CLIENTS.CONFIGURE_THE_TEMPLATE' | translate }} </b>

                    {{ 'SETTINGS_CLIENTS.TEXT_1' | translate }}

                </span>

            </div>

        </div>

        <div class="col-lg-12 col-md-12 mt-5 p-xl-0">

            <!-- form -->
            <form [formGroup]="importClientForm" novalidate *ngIf="importClientForm">
                <div class="row">


                    <!-- template_excel_client_company_id -->

                    <div class="col-xl-6 col-12 mb-3">

                        <label for="forName">{{ 'SETTINGS_CLIENTS.EXCEL_IMPORT_TEMPLATE' | translate }}:

                        <span class="text-danger">*</span>

                    </label>

                        <div class="form-group mt-1">

                            <select name="forName" class="form-control form-select form-control-lg" [formControlName]="forName">

                                <option value="">{{ 'GENERAL.SELECT' | translate }}</option>

                                <option [value]="template.id" *ngFor="let template of templates">{{ template.name }}</option>

                            </select>


                        </div>

                        <ng-container *ngFor="let validation of messages.forName">

                            <div class="w-100 text-justifay">

                                <small class="form-control-feedback text-danger" *ngIf="importClientForm.get(forName).hasError(validation.type) && (importClientForm.get(forName).dirty || importClientForm.get(forName).touched)"> {{ validation.message | translate }} </small>

                            </div>

                        </ng-container>


                    </div>

                    <!-- end template_excel_client_company_id -->

                    <!-- document -->

                    <div class="col-xl-6 col-12 mb-3">

                        <label for="headlineName">{{ 'SETTINGS_CLIENTS.SELECT_AN_EXCEL_FILE' | translate }}

                            <span class="text-danger">*</span>

                        </label>

                        <div class="form-group form-group-file mt-1">
                            <input #file type="file" (change)="onFileSelect($event)" formControlName="document" accept=".xls, .xlsx" class="form-control form-control-1" hidden>

                            <div class="input-group" (click)="file.click()">
                                <button class="btn btn-light select-file" type="button" id="button-addon1">{{ 'GENERAL.SELECT_FILE' | translate }}</button>
                                <input type="text" class="form-control select-file-input" [value]="nameFile" [placeholder]="'GENERAL.NOTHING_SELECTED' | translate" aria-label="Example text with button addon" aria-describedby="button-addon1">
                            </div>
                        </div>

                        <!-- <div class="form-group mt-1">

                            <input type="file" (change)="changeInput($any($event).target.files[0])" accept=".xls, .xlsx" class="form-control form-control-1" id="card-holder-name" placeholder="Datos titular" formControlName="excel_file">

                        </div> -->

                        <ng-container *ngFor="let validation of messages.document">
                            <div class="w-100 text-justifay">
                                <small class="form-control-feedback text-danger" *ngIf="importClientForm.get('document').hasError(validation.type) && (importClientForm.get('document').dirty || importClientForm.get('document').touched)"> {{ validation.message | translate }} </small>
                            </div>
                        </ng-container>

                    </div>

                    <!-- end document -->

                </div>
            </form>


        </div>

        <div class="col-12 text-xl-right text-center p-xl-0 mt-4">
            <div class="btn-list">

                <button type="button" class="btn btn-secondary-general point" [disabled]="!fileData && importClientForm && !importClientForm.valid" (click)="submit()">
                    {{ 'GENERAL.UPLOAD_FILE' | translate }}
                </button>
            </div>

        </div>


    </div>

</div>