export interface SettingsDocumentForm {

    id: number,
    name: string,
    description: string,
    template_category_type_id: number,
    template_special_type_id: number,
    favorite: boolean,
    content: string,
    is_active: boolean,
    created_by: string,
    updated_by: string,
    created_at: string,
    updated_at: string,
    deleted_at: string,
    template_category_type: {
        id: number,
        name: string,
        module_id: number,
        module: {
            id: number,
            name: string
        }
    },
    template_special_type: {
        id: number,
        name: string
    }
    
}

export class SettingsDocumentForm  implements SettingsDocumentForm  {
	constructor(){
        this.id = 0,
        this.id = 0,
        this.name = '',
        this.description = '',
        this.template_category_type_id = 0,
        this.template_special_type_id = 0,
        this.favorite = false,
        this.content = '',
        this.is_active = false,
        this.created_by = '',
        this.updated_by = '',
        this.created_at = '',
        this.updated_at = '',
        this.deleted_at = '',
        this.template_category_type = {
            id: 0,
            name: '',
            module_id: 0,
            module: {
                id: 0,
                name: ''
            }
        },
        this.template_special_type = {
            id: 0,
            name: ''
        }
       
	}
}
