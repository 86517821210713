<div class="app sidebar-mini ltr light-mode" (mouseenter)="hovermenu()">

    <div class="page">

        <div class="page-main">

            <!-- alerte de suscripción -->

            <ng-container *ngIf="showSuscription">

                <div class="alert alert-warning div-bar text-center alert-color" role="alert" [ngClass]="{'mb-0':showMenu2 ,' change-menu-position': showMenu2 }">

                    <button type="button" class="btn-close" (click)="hiddenALert()">×</button>

                    <span class="title"> {{ 'GENERAL.YOU_HAVE_TRIAL_DAYS_LEFT' | translate }} {{ daysDemo }} {{ 'GENERAL.YOU_HAVE_TRIAL_DAYS_LEFT_1' | translate }} <b class="b-style point" (click)="redirectSuscribe()"> {{ 'GENERAL.UPGRADE_YOUR_PLAN_AND_GET_FULL_ACCESS' | translate }} </b></span>

                </div>

            </ng-container>


            <!-- end alerta de suscripción -->

            <!-- app-Header -->

            <finergia-core-header [showMenu2]="showMenu2" [showSuscription]="showSuscription"></finergia-core-header>

            <!-- /app-Header -->

            <finergia-core-sidebar [showSuscription]="showSuscription" *ngIf="!showMenu2 "></finergia-core-sidebar>

            <div class="main-content app-content " [ngClass]="{ 'app-content': !showMenu2} ">
                <div class="side-app" [ngClass]="{'p-xl-0': home, 'mt-8':showSuscription}">

                    <div class="toast-container position-fixed top-0 end-0 p-3" style="z-index: 99999;">
                        <div id="liveToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false" style="z-index: 99999;" [ngClass]="{'bg-success': notification && notification.type_message === 'success', 'bg-danger': notification && notification.type_message === 'error' , 'bg-default': !notification}">
                            <div class="toast-header" [ngClass]="{'bg-success': notification && notification.type_message === 'success', 'bg-danger': notification && notification.type_message === 'error' , 'bg-default': !notification}">
                                <strong class="me-auto" *ngIf="notification && notification.data && notification.data.type">{{ title_toast | translate  }}</strong >
                                    <strong class="me-auto" *ngIf="notification && !notification.data">{{ 'GENERAL.INFO' | translate }}</strong >
                                <button type="button" class="btn-close ms-auto" data-bs-dismiss="toast">
                                    <img src="assets/icon/close.svg" style="width: 14px;">
                                </button>
                            </div>
                            <div class="toast-body" *ngIf="notification && notification.message">
                                <span class="me-auto" [ngClass]="{'success': notification.type_message === 'success', 'error': notification.type_message === 'error'}">{{ notification.message | translate }}</span>
                                <a [ngClass]="{'a-success': notification.type_message === 'success', 'a-danger': notification && notification.type_message === 'error'}" 
                                (click)="viewNotification(notification.data.type, notification.data.id)" 
                                *ngIf="notification && notification.data && notification.data.type && notification.data.type !== 'upload_book' && notification && notification.data && notification.data.type && notification.data.type !== 'imported_collection_payment' && notification && notification.data && notification.data.type && notification.data.type !== 'integrator_sync' 
                                &&  notification && notification.data && notification.data.type && notification.data.type !== 'company'  
                                && notification.data.id && notification.data.id !== null">Ver</a>

                            </div>
                        </div>
                    </div>

                    <!-- CONTAINER -->
                    <div class="main-container container-fluid" [ngClass]="{'pt-1': showSuscription}">
                        <router-outlet></router-outlet>
                    </div>
                    <!-- CONTAINER END -->
                </div>
            </div>

        </div>


    </div>
    <!--app-content open-->

    <!--app-content close-->

</div>