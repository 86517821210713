
export class ExpensesMessages {
    expensesTypesMessages = {
        name: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        suggestions: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        ledgeraccount: [
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ],
        tax:[
            {type: 'required', message: 'VALIDATIONS.REQUIRED_FIELD' },
        ]
    };

    getExpensesMessages() {
        return this.expensesTypesMessages;
    }
}
