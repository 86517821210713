<div class="m-5">
    <div class="row">
        <div class="col-10 mt-3 px-6">
            <span class="title">
                {{ 'FINANCIAL_ANALYSIS.IMPORT_JOURNAL_LEDGER' | translate }}
            </span>
        </div>
        <div class="col-2 text-end px-6 mt-3">
            <img src="assets/icon/close.svg" alt="" (click)="close(false)">
        </div>
    </div>
    <form [formGroup]="form" *ngIf="form">

        <div class="row">
            <div class="col-12 px-6 mt-3">
                <div class="container-message p-4">
                    <img src="assets/icon/interrogative-purple.svg" alt="">
                    <span class="text-message">
                        {{ 'FINANCIAL_ANALYSIS.YOU_SHOULD' | translate }} <a (click)="goExcelTemplates()" class="navigation-link point">{{ 'FINANCIAL_ANALYSIS.CONFIGURE_THE_TEMPLATE' | translate }}</a> {{'FINANCIAL_ANALYSIS.TO_LOAD_THE_EXCEL' | translate}} <br>
                        <span class="px-1">{{ 'FINANCIAL_ANALYSIS.TEXT_1' | translate }}</span>
                    </span>
                </div>
            </div>
        </div>
        <div class="row  mt-3">
            <div class="col-12 col-lg-6 mt-3 px-6">
                <div class="form-group">
                    <label for="template_excel_contable_company_id">{{ 'FINANCIAL_ANALYSIS.EXCEL_IMPORT_TEMPLATE' | translate }} <span class="text-red">*</span></label>
                    <select name="month" class="form-control form-select form-control-lg"
                        formControlName="template_excel_contable_company_id">
                        <option [value]="0">{{ 'GENERAL.SELECT' | translate }}</option>
                        <option [value]="template.id" *ngFor="let template of templates">{{ template.name }}</option>
                    </select>
                </div>
            </div>
            <div class="col-12 col-lg-6 mt-3 px-6">
                <div class="form-group">
                    <label for="year">{{ 'GENERAL.YEAR' | translate }} <span class="text-red">*</span></label>
                    <input name="month" class="form-control form-control-lg" type="number" min="1"
                        formControlName="year">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-3 px-6">
                <div class="form-group">
                    <label for="document">{{ 'FINANCIAL_ANALYSIS.SELECT_AN_EXCEL_FILE' | translate }}<span class="text-red">*</span></label>
                    <div class="form-group-file">

                        <input #file type="file" (change)="onFileSelect($event)" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        class="form-control form-control-lg" hidden formControlName="document">

                        <div class="input-group" (click)="file.click()">
                            <button class="btn btn-light select-file" type="button" id="button-addon1">{{ 'GENERAL.SELECT_FILE' | translate }}</button>
                            <input type="text" class="form-control form-control-lg select-file-input" [value]="nameFile" [placeholder]="'GENERAL.NOTHING_SELECTED' | translate" aria-label="Example text with button addon" aria-describedby="button-addon1">
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="error">
            <div class="col-12 px-6">
                <finergia-core-error-alert [error]="error"></finergia-core-error-alert>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mb-3 px-6 mt-3 text-end">
                <button class="btn btn-secondary" (click)="submit()" [disabled]="!form.valid">{{ 'GENERAL.UPLOAD_FILE' | translate }}</button>
            </div>
            
        </div>
    </form>
</div>