export interface Forms {
    id: number,
    name: string,
    form_type_id: number,
    form_type:{
        id: number,
        name:''
    },
    is_active:false

   
}

export class Forms  implements Forms  {
	constructor(){
    this.id = 0,
    this.name = '',
    this.form_type_id = 0,
    this.form_type = {
        id: 0,
        name:''
    },
    this.is_active =false

	}
}
