export interface UserClients {

    address: string,
    city: string,
    created_at: string,
    default_language: string,
    email: string,
    id: number,
    identification_document: string,
    last_name: string,
    name: string,
    permissions: [],
    phone: string,
    password: string,
    password_confirmation: string,
    profile: {
        profile_id: number, 
        profile: {
            id: number, 
            name: string
        }
    },
    updated_at: string,
    image: string,
    url_image: string,
    user_responsible_id: number
}

export class UserClients  implements UserClients  {
	constructor(){
        this.address = '',
        this.city =  ''
        this.created_at = '',
        this.default_language = '',
        this.email = '',
        this.id = 0,
        this.identification_document = '',
        this.last_name = '',
        this.name = '',
        this.permissions = []
        this.phone = '',
        this.password = '',
        this.password_confirmation = '',
        this.profile= {
            profile_id: 0, 
            profile: {
                id: 0, 
                name: ''
            }
        },
        this.updated_at = '',
        this.image = '',
        this.url_image = '',
        this.user_responsible_id = 0
	}
}
